import createAxiosInstance from './_axiosConfig';
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    EMAIL_SENT,
    SMS_SENT,
    RESET_PASSWORD_CONFIRMED,
    EMAIL_CONFIRMED,
    PHONE_NUMBER_CONFIRMED,
    IMPERSONATE_USER,
    SET_PREVIOUS_USER,
    SWITCH_TO_USER,
    LOGOUT,
    CHANGED_PASSWORD,
    LEAD_USER_REGISTERED,
    CLEAR_ERRORS_SESSION,
    SET_ACTIVE_DEVICE,
    CLEAR_CHANGED_PASSWORD,
    CHANGED_EMAIL,
    CLEAR_CHANGED_EMAIL,
    UPDATE_USER_CONTEXT
} from './types';

import setAxiosToken from '../_utils/setAxiosToken';
import { API_USERS } from '../_constants/apiConstants';
import isEmpty from '../_utils/isEmpty';
import store from '../_utils/store';
import { JWT_TOKEN, MAIN_TOKEN, MAIN_USER } from '../_constants/localStorageConstants';
import { clearTokens, getRefreshToken, resetBiometrics, setTokens } from '../../_tokenService';

export const registerUser = (user, navigationFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/register`, user)
            .then(response => {
                setTokens(response.data.jwtToken, response.data.refreshToken);
                setAxiosToken(response.data.jwtToken);
                dispatch(setCurrentUser(response.data.userPermissions, response.data.jwtToken));
                if (!isEmpty(navigationFunction)) {
                    navigationFunction(response.data);
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const leadRegister = (requestData, callBack) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/leadRegister`, requestData)
            .then(response => {
                //Manual login
                if (!isEmpty(response.data.canLogin) && response.data.canLogin) {
                    dispatch({
                        type: LEAD_USER_REGISTERED,
                        payload: response.data
                    });
                }
                //Auto Login
                if (!isEmpty(response.data.jwtToken) && !isEmpty(response.data.userPermissions)) {
                    setTokens(response.data.jwtToken, response.data.refreshToken);
                    setAxiosToken(response.data.jwtToken);
                    dispatch(setCurrentUser(response.data.userPermissions, response.data.jwtToken));
                    if (!isEmpty(callBack)) {
                        callBack(requestData);
                    }
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const setCanLogin = (canLogin) => dispatch => {
    let payload = { canLogin: canLogin };
    dispatch({
        type: LEAD_USER_REGISTERED,
        payload: payload
    })
}

export const loginUser = (user, navigationFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    _login(user, `${API_USERS}/${portalCountryCode}/login`, dispatch, navigationFunction);
}

export const externalLogin = (user, navigationFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return _login(user, `${API_USERS}/${portalCountryCode}/externalLogin`, dispatch, navigationFunction);
}

export const twoFactorLogin = (user, navigationFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return _login(user, `${API_USERS}/${portalCountryCode}/twoFactorLogin`, dispatch, navigationFunction);
}

export const biometricLogin = (user, navigationFunction,) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return _login(user, `${API_USERS}/${portalCountryCode}/biometricLogin`, dispatch, navigationFunction);
}

export const updateBiometrics = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.put(`${API_USERS}/${portalCountryCode}/updateBiometrics`, request)
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const generateTwoFactorToken = (userHashId = "") => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.get(`${API_USERS}/${portalCountryCode}/generateTwoFactorToken?hashId=${userHashId}`)
            .then(res => {
                dispatch({
                    type: SMS_SENT,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const resetPassword = (email) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/resetPassword/${email}`)
            .then(res => {
                dispatch({
                    type: EMAIL_SENT,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const confirmResetPassword = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/confirmResetPassword`, request)
            .then(res => {
                dispatch({
                    type: RESET_PASSWORD_CONFIRMED,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const changePassword = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/changepassword`, request)
            .then(res => {
                dispatch({
                    type: CHANGED_PASSWORD,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const clearChangedPassword = () => dispatch => {
    dispatch({
        type: CLEAR_CHANGED_PASSWORD,
        payload: ''
    })
}

export const changeEmail = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/changeEmail`, request)
            .then(res => {
                dispatch({
                    type: CHANGED_EMAIL,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const clearChangedEmail = () => dispatch => {
    dispatch({
        type: CLEAR_CHANGED_EMAIL,
        payload: ''
    })
}

export const clearSmsSent = () => dispatch => {
    dispatch({
        type: SMS_SENT,
        payload: ''
    })
}

export const verifyEmail = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/verifyEmail`, request)
            .then(res => {
                dispatch({
                    type: EMAIL_SENT,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const confirmEmail = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/confirmEmail`, request)
            .then(res => {
                dispatch({
                    type: EMAIL_CONFIRMED,
                    payload: !isEmpty(res.data)
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const verifyPhoneNumber = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/verifyPhoneNumber`, request)
            .then(res => {
                dispatch({
                    type: SMS_SENT,
                    payload: !isEmpty(res.data)
                });
                dispatch({
                    type: UPDATE_USER_CONTEXT,
                    payload: { ...res.data, user: res.data.user }
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const confirmPhoneNumber = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/confirmPhoneNumber`, request)
            .then(res => {
                dispatch({
                    type: PHONE_NUMBER_CONFIRMED,
                    payload: !isEmpty(res.data)
                });
                dispatch({
                    type: UPDATE_USER_CONTEXT,
                    payload: { ...res.data, user: res.data.user }
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

//pass in a bool for whether the verifaction sms has been sent or not
export const setSmsSent = (isSent) => dispatch => {
    dispatch({
        type: SMS_SENT,
        payload: isSent
    });
}

//pass in a bool for whether the auth confirmation has been sent
export const setPhoneNumberConfirmed = (isConfirmed) => dispatch => {
    dispatch({
        type: PHONE_NUMBER_CONFIRMED,
        payload: isConfirmed
    });
}

export const logoutUser = (navigationFunction) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/logout`)
            .then(res => {
                _externalLogOut();
                dispatch({ type: LOGOUT, payload: {} });
                dispatch({ type: CLEAR_ERRORS_SESSION, payload: {} });
                clearTokens();
                setAxiosToken(false);
                if (!isEmpty(navigationFunction)) {
                    navigationFunction();
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const logoutInvalidSession = (navigationFunction) => dispatch => {
    _externalLogOut();
    dispatch({ type: LOGOUT, payload: {} });
    dispatch({ type: CLEAR_ERRORS_SESSION, payload: {} });
    clearTokens();
    setAxiosToken(false);
    if (!isEmpty(navigationFunction)) {
        navigationFunction();
    }
    _externalLogOut();
}


export const getUserPermissions = (token) => dispatch => {
    return createAxiosInstance().then(axios => {
        return axios.get(`${API_USERS}/getuserpermissions`)
            .then(res => {
                let currentUser = { user: res.data, token: token };
                dispatch({
                    type: SET_CURRENT_USER,
                    payload: currentUser
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    })
}

export const impersonate = (userId, navigationFunction, setLocalStorageFunction, getLocalStorageFunction) => dispatch => {

    let currentStore = store.getState();
    let portalCountryCode = currentStore.portal.data.cca2;
    let userObject = { userId: userId };
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_USERS}/${portalCountryCode}/impersonate`, userObject)
            .then(res => {
                const loginResponse = res.data;
                const token = loginResponse.jwtToken;
                const newUser = loginResponse.userPermissions;

                if (!isEmpty(getLocalStorageFunction) && !isEmpty(setLocalStorageFunction)) {
                    let oldToken = getLocalStorageFunction(JWT_TOKEN);
                    let oldUser = currentStore.auth.currentUser.user;

                    setLocalStorageFunction(JWT_TOKEN, token);
                    setLocalStorageFunction(MAIN_TOKEN, oldToken);
                    setLocalStorageFunction(MAIN_USER, JSON.stringify(oldUser));

                    setAxiosToken(token);

                    dispatch({
                        type: IMPERSONATE_USER,
                        payload: { new: newUser, old: oldUser, token: token }
                    });

                    if (!isEmpty(navigationFunction)) {
                        navigationFunction();
                    }
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const switchToUser = (user, navigationFunction, setLocalStorageFunction, getLocalStorageFunction) => dispatch => {

    let mainToken = getLocalStorageFunction(MAIN_TOKEN);
    setLocalStorageFunction(JWT_TOKEN, mainToken);
    setAxiosToken(mainToken);

    dispatch({
        type: SWITCH_TO_USER,
        payload: { user: user, token: mainToken }
    });

    if (!isEmpty(navigationFunction)) {
        navigationFunction();
    }

}

export const setPreviousUser = user => dispatch => {
    dispatch({
        type: SET_PREVIOUS_USER,
        payload: user
    })
}

export const setCurrentUser = (user, token) => {
    let currentUser = { user: user, token: token };

    return {
        type: SET_CURRENT_USER,
        payload: currentUser
    }
}

export const setUserActiveDevice = (mobileDevice) => {
    return {
        type: SET_ACTIVE_DEVICE,
        payload: mobileDevice
    }
}

export const refreshToken = () => dispatch => {
    return createAxiosInstance().then(async axios => {
        const refreshToken = await getRefreshToken();
        if (!isEmpty(refreshToken)) {
            return axios.post(`${API_USERS}/refreshToken`, { refreshToken: refreshToken }).then(response => {
                setTokens(response.data.jwtToken, response.data.refreshToken);
                setAxiosToken(response.data.jwtToken);
                dispatch(setCurrentUser(response.data.userPermissions, response.data.jwtToken));
            }).catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
        }
    });
}

function _login(user, endpoint, dispatch, navigationFunction) {
    return createAxiosInstance().then(axios => {
        return axios.post(endpoint, user)
            .then(response => {
                setTokens(response.data.jwtToken, response.data.refreshToken);
                setAxiosToken(response.data.jwtToken);
                dispatch(setCurrentUser(response.data.userPermissions, response.data.jwtToken));
                if (!isEmpty(navigationFunction)) {
                    navigationFunction(response.data);
                    if (response.data.requiresTwoFactor) {
                        dispatch({ type: SMS_SENT, payload: true });
                    }
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
                if (endpoint.includes("biometric")) {
                    resetBiometrics();
                }
            });
    });
}

function _externalLogOut() {
    if (!isEmpty(window.gapi) && !isEmpty(window.gapi.auth2) && !isEmpty(window.gapi.auth2.getAuthInstanc)) {
        let auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            console.info('Google signed out.');
        });
    }
    if (!isEmpty(window.FB) && !isEmpty(window.FB.logout)) {
        window.FB.logout(function (response) {
            console.info('Facebook logged out.');
        });
    }
}