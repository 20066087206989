import {
    GET_STORES,
    SET_STORE_FOR_EDIT,
    GET_ERRORS,
    POST_STORES,
    PUT_STORES,
    DELETE_STORES,
    CLEAR_STORES,
    CLEAR_STORES_SUCCESS,
} from "./types";
import createAxiosInstance from "./_axiosConfig";
import {
    API_STORES,
    API_BYTES_RESPONSE,
} from "../_constants/apiConstants";
import serializeQuery from "../_utils/serializeQuery";
import buildQueryMeta from "../_utils/buildQueryMeta";
import buildResponseMeta from "../_utils/buildResponseMeta";
import isEmpty from "../_utils/isEmpty";
import buildQueryDelete from "../_utils/buildQueryDelete";
import store from "../_utils/store"; //nb! this store refers to the redux store
import { STORES_NAME, EXCEL_TYPE } from "../_constants/excelConstants";

export const getStores =
    (storeId = "", qsrMode = null, isActive = null) =>
        (dispatch) => {
            let query = "";
            let selectedStore = false;

            if (!isEmpty(storeId)) {
                selectedStore = true;
                storeId = "/" + storeId;
            }
            if (!isEmpty(qsrMode)) {
                query = query + "&qsrMode=" + qsrMode;
            }
            if (!isEmpty(isActive)) {
                query = query + "&isActive=" + isActive;
            }
            let portalCountryCode = store.getState().portal.data.cca2;

            return createAxiosInstance().then((axios) => {
                return axios.get(
                        `${API_STORES}/${portalCountryCode}${storeId}?pageSize=2000${query}`
                    )
                    .then((res) => {
                        if (selectedStore) {
                            dispatch({
                                type: SET_STORE_FOR_EDIT,
                                payload: res.data,
                            });
                        }
                        dispatch({
                            type: GET_STORES,
                            payload: res.data,
                        });
                    })
                    .catch((err) => {
                        dispatch({
                            type: GET_ERRORS,
                            payload: err.response,
                        });
                    });
            });
        };

// here we pass in the function from the view to save the file to the device so we can reuse this on web and mobile
// web use 'file-saver' library -> saveAs() function
export const exportStores =
    (meta, downloadFunction = null) =>
        (dispatch) => {
            if (downloadFunction) {
                let query = buildQueryMeta(meta);
                let portalCountryCode = store.getState().portal.data.cca2;
                return createAxiosInstance().then((axios) => {
                    return axios.get(
                            `${API_STORES}/${portalCountryCode}?${serializeQuery(
                                query
                            )}`,
                            API_BYTES_RESPONSE
                        ) //Should I limit this???
                        .then((res) => {
                            let blob = new Blob([res.data], EXCEL_TYPE);
                            downloadFunction(blob, STORES_NAME);
                        })
                        .catch((err) => {
                            dispatch({
                                type: GET_ERRORS,
                                payload: err.response,
                            });
                        });
                });
            }
        };

export const getStoresPage = (meta) => (dispatch) => {
    return _getPage(meta, dispatch);
};

export const createStore = (storeData, history) => (dispatch) => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${API_STORES}/${portalCountryCode}`;
    return createAxiosInstance().then((axios) => {
        return axios.post(portalizedEndpoint, storeData)
            .then((res) => {
                dispatch({
                    type: POST_STORES,
                    payload: res.data,
                });
                history.push("/stores/edit/" + res.data.id);
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
            });
    });
};

export const updateStore = (storeId, storeData) => (dispatch) => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${API_STORES}/${portalCountryCode}`;

    return createAxiosInstance().then((axios) => {
        return axios.put(portalizedEndpoint + "/" + storeId, storeData)
            .then((res) => {
                dispatch({
                    type: PUT_STORES,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
            });
    });
};

export const deleteStore = (storeId) => (dispatch) => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${API_STORES}/${portalCountryCode}`;

    return createAxiosInstance().then((axios) => {
        return axios.delete(portalizedEndpoint + "/" + storeId)
            .then((res) => {
                dispatch({
                    type: DELETE_STORES,
                    payload: [storeId], //reducer expects payload to be an array of ids
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
            });
    });
};

export const deleteStoresBulk = (storeIds, meta) => (dispatch) => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${API_STORES}/${portalCountryCode}`;

    let query = buildQueryDelete(storeIds);

    return createAxiosInstance().then((axios) => {
        return axios.delete(portalizedEndpoint + "?" + serializeQuery(query))
            .then((res) => {
                dispatch({
                    type: DELETE_STORES,
                    payload: storeIds,
                });
            })
            .then((res) => {
                _getPage(meta, dispatch);
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
            });
    });
};

export const clearStoresSuccess = () => (dispatch) => {
    dispatch({
        type: CLEAR_STORES_SUCCESS,
        payload: "",
    });
};

export const clearStores = () => (dispatch) => {
    dispatch({
        type: CLEAR_STORES,
        payload: "",
    });
};

/** Helper functions */

//internal get stores page function - used in getStoresPage() and deleteStoresBulk()
let _getPage = (meta, dispatch) => {
    let query = buildQueryMeta(meta);
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${API_STORES}/${portalCountryCode}`;

    return createAxiosInstance().then((axios) => {
        return axios.get(portalizedEndpoint + "?" + serializeQuery(query))
            .then((res) => {
                res = buildResponseMeta(res, meta);
                dispatch({
                    type: GET_STORES,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
            });
    });
};
