import {
    GET_ERRORS,
    SET_NOTICATIONS,
    SET_NOTICATIONS_IS_LOADING,
    SET_NOTICATIONS_IS_FETCHING,
    SET_NOTICATIONS_IS_COMPLETE,
    SET_NOTICATIONS_PAGE,
    CLEAR_NOTIFICATIONS,
    REMOVE_ITEM_AT_INDEX,
    UPDATE_ITEM_COUNT,
    PLAY_SOUND,
    IS_PLAYING,
    MUTE,
    WEB_NOTIFICATION_TYPES,
} from "./types";
import createAxiosInstance from "./_axiosConfig";
import store from "../../wumdrophubsreactshared/_utils/store";
import isEmpty from "../../wumdrophubsreactshared/_utils/isEmpty";
import serializeQuery from "../_utils/serializeQuery";

export const getWebNotifications =
    (page, read, webNotificationTypeIdFilter, webNotificationTypeIds) =>
        (dispatch) => {
            let groupQuery = [];

            if (!isEmpty(webNotificationTypeIds)) {
                webNotificationTypeIds.forEach((webNotificationTypeId) => {
                    groupQuery.push({
                        field: "webNotificationTypeIds",
                        value: webNotificationTypeId,
                    });
                });
            }

            let portalCountryCode = store.getState().portal.data.cca2;

            return createAxiosInstance().then((axios) => {
                let endPointUrl = `/WebNotifications/${portalCountryCode}?page=${page}&read=${read}&${serializeQuery(
                    groupQuery
                )}`;

                if (
                    !isEmpty(webNotificationTypeIdFilter) &&
                    webNotificationTypeIdFilter !== 0
                ) {
                    endPointUrl = `${endPointUrl}&webNotificationTypeId=${webNotificationTypeIdFilter}`;
                }

                return axios.get(endPointUrl)
                    .then((res) => {
                        return dispatch({
                            type: SET_NOTICATIONS,
                            payload: {
                                items: res.data.items,
                                totalPages: res.data.paging.totalPages,
                                totalCurrentItemCount: res.data.paging.totalItems,
                                aggregationDictionary: res.data.aggregationDictionary,
                            },
                        });
                    })
                    .catch((err) => {
                        dispatch({
                            type: GET_ERRORS,
                            payload: err.response,
                        });
                    });
            });
        };

export const getTotalItemCount = (webNotificationTypeIds) => (dispatch) => {
    let query = [];

    if (!isEmpty(webNotificationTypeIds)) {
        webNotificationTypeIds.forEach((webNotificationTypeId) => {
            query.push({
                field: "webNotificationTypeIds",
                value: webNotificationTypeId,
            });
        });
    }

    query.push({ field: "read", value: false });
    query.push({ field: "countOnly", value: true });

    let portalCountryCode = store.getState().portal.data.cca2;

    return createAxiosInstance().then((axios) => {
        return axios.get(`/WebNotifications/${portalCountryCode}?${serializeQuery(query)}`)
            .then((res) => {
                return dispatch({
                    type: UPDATE_ITEM_COUNT,
                    payload: { totalItemCount: res.data.paging.totalItems },
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
            });
    });
};

export const getWebNotificationTypes =
    (webNotificationTypeIds) => (dispatch) => {
        let query = [];

        if (!isEmpty(webNotificationTypeIds)) {
            webNotificationTypeIds.forEach((webNotificationTypeId) => {
                query.push({
                    field: "webNotificationTypeIds",
                    value: webNotificationTypeId,
                });
            });
        }

        return createAxiosInstance().then((axios) => {
            return axios.get(`/WebNotifications/webnotificationtypes?${serializeQuery(query)}`)
                .then((res) => {
                    return dispatch({
                        type: WEB_NOTIFICATION_TYPES,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response,
                    });
                });
        });
    };

export const removeItemAtIndex = (webNotificaionId, index) => (dispatch) => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return dispatch({
        type: REMOVE_ITEM_AT_INDEX,
        payload: index,
    }).then(() => {
        return createAxiosInstance().then((axios) => {
            return axios.get(
                    `/WebNotifications/markread/${portalCountryCode}/${webNotificaionId}`
                )
                .catch((err) => {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response,
                    });
                });
        });
    });
};

export const setIsLoading = (isLoading) => (dispatch) => {
    return dispatch({
        type: SET_NOTICATIONS_IS_LOADING,
        payload: isLoading,
    });
};

export const setIsFetching = (isFetching) => (dispatch) => {
    return dispatch({
        type: SET_NOTICATIONS_IS_FETCHING,
        payload: isFetching,
    });
};

export const setIsComplete = (isComplete) => (dispatch) => {
    return dispatch({
        type: SET_NOTICATIONS_IS_COMPLETE,
        payload: isComplete,
    });
};

export const setPage = (page) => (dispatch) => {
    return dispatch({
        type: SET_NOTICATIONS_PAGE,
        payload: page,
    });
};

export const clearNotifications = () => (dispatch) => {
    return dispatch({
        type: CLEAR_NOTIFICATIONS,
    });
};

export const setPlay = (play) => (dispatch) => {
    return dispatch({
        type: PLAY_SOUND,
        payload: play,
    });
};

export const setIsPlaying = (isPlaying) => (dispatch) => {
    return dispatch({
        type: IS_PLAYING,
        payload: isPlaying,
    });
};

export const setMute = (mute) => (dispatch) => {
    return dispatch({
        type: MUTE,
        payload: mute,
    });
};
