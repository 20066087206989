import axios from 'axios';
import { getConfigAsync } from './config';
import { clearTokens, getJwtToken, getRefreshToken, setTokens } from '../../_tokenService';
import { API_USERS } from '../_constants/apiConstants';
import setAxiosToken from './setAxiosToken';

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
    refreshSubscribers.push(callback);
};

const onRefreshed = (newJwtToken) => {
    refreshSubscribers.forEach((callback) => callback(newJwtToken));
    refreshSubscribers = [];
};

const refreshAccessToken = async () => {
    try {
        if (!isRefreshing) {
            isRefreshing = true;
            const jwtToken = await getJwtToken();
            const refreshToken = await getRefreshToken();
            let conf = await getConfigAsync();
            const response = await axios.post(`${conf.REACT_APP_HOST_PROTOCOL}://${conf.REACT_APP_API_HOST}/${conf.REACT_APP_API_PREFIX}${API_USERS}/refreshToken`, { refreshToken: refreshToken, jwtToken: jwtToken });
            await setTokens(response.data.jwtToken, response.data.refreshToken);
            setAxiosToken(response.data.jwtToken);
            isRefreshing = false;
            onRefreshed(response.data.jwtToken);
            return Promise.resolve(response.data.jwtToken);
        }
        return new Promise((resolve) => {
            subscribeTokenRefresh((newJwtToken) => resolve(newJwtToken));
        });

    } catch (error) {
        isRefreshing = false;
        if (error.response.status === 401)
            clearTokens();
        return Promise.resolve(error);
    }
};
export default refreshAccessToken;