import { GET_COUNTRY_BY_COUNTRYCODE, GET_ERRORS } from './types';
import { API_COUNTRIES } from '../_constants/apiConstants';
import createAxiosInstance from './_axiosConfig';

export const getCountryByCountryCode = (countryCode) => dispatch => {
    return createAxiosInstance().then(axios => {
        return axios.get(API_COUNTRIES + '/' + countryCode)
        .then(res => {
            dispatch({
                type: GET_COUNTRY_BY_COUNTRYCODE,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    });
}

//this is to clear the actual delivery data from the deliveries object in redux
export const setPortalCountryData = (data) => dispatch => {
    return dispatch({
        type: GET_COUNTRY_BY_COUNTRYCODE,
        payload: data
    })
}