import React, { Component } from 'react';
import { ADDRESSES, STORES } from 'wumdrophubsreactshared/_constants/typeConstants';
import isEmpty from 'wumdrophubsreactshared/_utils/isEmpty';
import { ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import convertUtcToLocalDate from 'wumdrophubsreactshared/_utils/convertUtcToLocalDate';

//actions
import { getAvailableDates, getAvailableTimeslots } from 'wumdrophubsreactshared/_actions/deliveryWindows';
import { getTerritories } from 'wumdrophubsreactshared/_actions/territories';
import { getStores } from 'wumdrophubsreactshared/_actions/stores';
import { getStoresSelect } from 'wumdrophubsreactshared/_actions/storesSelect';
// @material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import styles from './LocationTimeForm.css';

import AddressPicker from '../pickers/AddressPicker';
import DeliveryMap from '../../maps/DeliveryMap';

import {
    ICON_GENERATORE_DELIVERY_ICONS,
    ICON_GENERATOR_BASE_URL,
    MARKER_LEGEND_ENUMS,
    ICON_VERSION
} from 'wumdrophubsreactshared/_constants/apiConstants';
import StoresIcon from '@material-ui/icons/StoreMallDirectory';
import StorePicker from '../pickers/StorePicker';
import DatePicker from '../pickers/DatePicker';
import { SECONDARY_COLOR } from "../../../wumdrophubsreactshared/_constants/styleConstants";
import { setError } from 'wumdrophubsreactshared/_actions/errors';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { EmailOutlined } from "@material-ui/icons";
import PhoneNumberPicker from '../pickers/PhoneNumberPicker';
import { FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { Fragment } from 'react';
import { IconButton, Grid } from '@material-ui/core';

//fetch the pickup icon from our icon generator microservice
let pickUpMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=P&markerType=${MARKER_LEGEND_ENUMS.PICK_UP}&version=${ICON_VERSION}&markerColour=${encodeURIComponent(SECONDARY_COLOR)}`;
let dropOffMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=D&markerType=${MARKER_LEGEND_ENUMS.DROP_OFF}&version=${ICON_VERSION}&markerColour=${encodeURIComponent(SECONDARY_COLOR)}`;
let storePickUpMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=P&markerType=${MARKER_LEGEND_ENUMS.STORE_PICK_UP}&version=${ICON_VERSION}&markerColour=${encodeURIComponent(SECONDARY_COLOR)}`;
let storeDropOffMarkerIcon = `${ICON_GENERATOR_BASE_URL}${ICON_GENERATORE_DELIVERY_ICONS}?label=D&markerType=${MARKER_LEGEND_ENUMS.STORE_DROP_OFF}&version=${ICON_VERSION}&markerColour=${encodeURIComponent(SECONDARY_COLOR)}`;

const ORIGIN_STORE_ADDRESS_PICKER = "originStoreAddressPicker";
const DESTINATION_STORE_ADDRESS_PICKER = "destinationStoreAddressPicker";
const ORIGIN_STORE_ID = "originStoreId";
const DESTINATION_STORE_ID = "destinationStoreId";

class LocationTimeForm extends Component {

    constructor(props) {
        super(props);
        let originStoreAddressPicker = !isEmpty(this.props.auth?.currentUser?.company);
        let destinationStoreAddressPicker = false;

        let { auth } = this.props;
        if (auth.isAuthenticated && !isEmpty(auth.currentUser) && !isEmpty(auth.currentUser.user)) {
            // if it's a store user and the address field is empty and it's not an originStore address already
            if (auth.currentUser.user.isStoreNormalUser && isEmpty(this.props.values.originStoreId) && isEmpty(this.props.values.originAddress)) {
                originStoreAddressPicker = true;
            }
        }

        if (!isEmpty(this.props.values.originStoreId) && !isEmpty(this.props.values.originStoreName)) {
            originStoreAddressPicker = true;
        }

        if (!isEmpty(this.props.values.destinationStoreId) && !isEmpty(this.props.values.destinationStoreName)) {
            destinationStoreAddressPicker = true;
        }

        if (this.props.showStorePickers && isEmpty(this.props.stores.data) && this.props.getStores) {
            this.props.getStores(undefined, undefined, true);
        }

        this.state = {
            originStoreAddressPicker,
            destinationStoreAddressPicker
        }

        //load territories
        if (!isEmpty(this.props.territories) && isEmpty(this.props.territories.data) && this.props.showMap) {
            this.props.getTerritories();
        }

        this.pickUpSelector = React.createRef();
        this.dropOffSelector = React.createRef();
        this.dateSelector = React.createRef();
        this.timeSelector = React.createRef();

        this.firstNameSelector = React.createRef();
    }

    componentDidMount() {
        const { auth } = this.props;
        if (auth.isAuthenticated && !isEmpty(auth.currentUser) && !isEmpty(auth.currentUser.user)) {
            this.props.getStores(undefined, undefined, true);
        }
    }


    handleAddressChange = (selected) => {
        const { values, setValues } = this.props;

        return new Promise((resolve, reject) => {

            let newValues = { ...values };
            let address = !selected.placeId ? null : { placeId: selected.placeId, description: selected.description };

            if (address) {
                //fetch coords first if we are displaying the selected addresses on a map
                selected.getAddress(selected, (data) => {

                    if (selected.placeId && !isEmpty(data)) {
                        address = data;
                    }
                    newValues[selected.field] = address;

                    setValues(newValues);
                    resolve(data);
                }, (err) => {
                    this.props.setError(err).then(() => {
                        reject(err);
                    });
                });
            } else {
                //else we just set the address
                newValues[selected.field] = address;
                setValues(newValues);
                resolve();
            }
        });
    }

    handleStoreSelect = (selected) => {
        return new Promise((resolve) => {
            const { values, setValues, stores } = this.props;

            let newValues = { ...values };

            let address = !selected.value ? null : { storeId: selected.value };

            if (!isEmpty(stores.data)) {
                //get store address coordinates
                stores.data.items.forEach(store => {
                    if (store.id === selected.value) {
                        address.coordinates = store.address.coordinates;
                    }
                })
            }

            if (selected.field === ORIGIN_STORE_ID) {
                newValues.originAddress = address;
            } else if (selected.field === DESTINATION_STORE_ID) {
                newValues.destinationAddress = address;
            }

            setValues(newValues);
            resolve();
        });
    }

    toggleStoreAddress = (name) => {
        const { values, setValues } = this.props;
        let newValues = { ...values };

        if (name === ORIGIN_STORE_ADDRESS_PICKER) {
            //clear origin address value on toggle
            newValues.originAddress = null;
        } else if (name === DESTINATION_STORE_ADDRESS_PICKER) {
            //clear destination address value on toggle
            newValues.destinationAddress = null;
        }

        setValues(newValues);

        this.setState((prevState) => ({
            [name]: !prevState[name]
        }));
    }

    handleAdminDateChange = (momentValue) => {

        let selected = { field: "deliveryDate", value: momentValue.format("YYYY-MM-DD") };

        this.handleDateChange(selected);
    }

    handleDateChange = (selected) => {
        return new Promise((resolve) => {
            const { values, setValues } = this.props;
            let newValues = { ...values };
            newValues[selected.field] = selected.value;
            newValues['deliveryTime'] = '00:00'; //Always reset time on date change
            setValues(newValues);
            resolve();
        });
    }

    render() {
        const {
            classes,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            showMap,
            portal,
            errors,
            touched,
            auth,
            showStorePickers,
            hideUserDetails } = this.props;

        const { originStoreAddressPicker, destinationStoreAddressPicker } = this.state;

        let thisHideUserDtails = !isEmpty(hideUserDetails) ? hideUserDetails : true;

        let deliveryDate = {};
        if (!isEmpty(values.deliveryDate)) {
            deliveryDate.key = values.deliveryDate;
            deliveryDate.value = convertUtcToLocalDate(values.deliveryDate, portal.data.timeZone, true);
            deliveryDate.label = deliveryDate.value;
        }

        let deliveryTime = {};
        if (!isEmpty(values.deliveryTime)) {
            deliveryTime.key = values.deliveryTime;
            deliveryTime.value = values.deliveryTime;
            deliveryTime.label = values.deliveryTime;
        }

        let originStoreValue = {};
        if (!isEmpty(values.originStoreId) && !isEmpty(values.originStoreName)) {
            originStoreValue.key = values.originStoreId;
            originStoreValue.value = values.originStoreName;
        }

        let destinationStoreValue = {};
        if (!isEmpty(values.destinationStoreId) && !isEmpty(values.destinationStoreName)) {
            destinationStoreValue.key = values.destinationStoreId;
            destinationStoreValue.value = values.destinationStoreName;
        }

        let portalCode = "";
        if (!isEmpty(portal) && !isEmpty(portal.data)) {
            portalCode = portal.data.cca2
        }

        return (
            <div>
                <Grid container>
                    <Grid item xs={!showStorePickers ? 12 : 10} md={!showStorePickers ? 12 : 11}>
                        {
                            showStorePickers && originStoreAddressPicker ?
                                <StorePicker
                                    ref={this.pickUpSelector}
                                    icon={storePickUpMarkerIcon}
                                    fieldName={ORIGIN_STORE_ID}
                                    id={ORIGIN_STORE_ID}
                                    handleBlur={handleBlur}
                                    label="Origin store"
                                    onSelect={this.handleStoreSelect}
                                    apiEntity={`${STORES}/${portalCode}`} // NB! you need to supply the name of the entity to search on in the API
                                    multiSelect={false}
                                    value={originStoreValue}
                                    placeholder={"Select pick-up store"}
                                    nextSelector={this.dropOffSelector}
                                /> :
                                <AddressPicker
                                    ref={this.pickUpSelector}
                                    icon={pickUpMarkerIcon}
                                    fieldName="originAddress"
                                    onSelect={this.handleAddressChange}
                                    handleBlur={handleBlur}
                                    apiEntity={ADDRESSES}
                                    multiSelect={false}
                                    value={values.originAddress}
                                    displayValue={"description"}
                                    displayKey={"placeId"}
                                    placeholder={"Enter pick-up address"}
                                    nextSelector={this.dropOffSelector}
                                />
                        }
                        <ErrorMessage name="originAddress">{msg => <div className={classes.feedback}>{msg}</div>}</ErrorMessage>
                    </Grid>
                    {showStorePickers &&
                        <Grid item xs={2} md={1}>
                            <IconButton
                                className={this.state.originStoreAddressPicker ? classes.buttonChecked : classes.button}
                                aria-label="Store"
                                onClick={() => this.toggleStoreAddress(ORIGIN_STORE_ADDRESS_PICKER)}
                            >
                                <StoresIcon />
                            </IconButton>
                        </Grid>}

                    <Grid item xs={!showStorePickers ? 12 : 10} md={!showStorePickers ? 12 : 11}>
                        {
                            destinationStoreAddressPicker ?
                                <StorePicker
                                    ref={this.dropOffSelector}
                                    fieldName={DESTINATION_STORE_ID}
                                    id={DESTINATION_STORE_ID}
                                    label="Destination store"
                                    onSelect={this.handleStoreSelect}
                                    apiEntity={`${STORES}/${portalCode}`} // NB! you need to supply the name of the entity to search on in the API
                                    multiSelect={false}
                                    value={destinationStoreValue}
                                    placeholder={"Select drop-off store"}
                                    icon={storeDropOffMarkerIcon}
                                    handleBlur={handleBlur}
                                    nextSelector={this.dateSelector}
                                /> :
                                <AddressPicker
                                    ref={this.dropOffSelector}
                                    icon={dropOffMarkerIcon}
                                    fieldName="destinationAddress"
                                    onSelect={this.handleAddressChange}
                                    handleBlur={handleBlur}
                                    apiEntity={ADDRESSES}
                                    multiSelect={false}
                                    value={values.destinationAddress}
                                    displayValue={"description"}
                                    displayKey={"placeId"}
                                    placeholder={"Enter drop-off address"}
                                    nextSelector={this.dateSelector}
                                />

                        }
                        <ErrorMessage name="destinationAddress">{msg => <div className={classes.feedback}>{msg}</div>}</ErrorMessage>
                    </Grid>

                    {showStorePickers &&
                        <Grid item xs={2} md={1}>
                            <IconButton
                                className={this.state.destinationStoreAddressPicker ? classes.buttonChecked : classes.button}
                                aria-label="Store"
                                onClick={() => this.toggleStoreAddress(DESTINATION_STORE_ADDRESS_PICKER)}
                            >
                                <StoresIcon />
                            </IconButton>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePicker
                            fieldName={"deliveryDate"}
                            id={"deliveryDate"}
                            label="Pick-up date"
                            onSelect={this.handleDateChange}
                            apiEntity={`DeliveryWindows/GetAvailableDates/${portalCode}?storeId=${values.originAddress?.storeId ?? values.destinationAddress?.storeId}&companyId=${values.companyId}`} // NB! you need to supply the name of the entity to search on in the API
                            multiSelect={false}
                            value={!isEmpty(deliveryDate) ? deliveryDate : null}
                            placeholder={"Select a pick-up date"}
                            handleBlur={handleBlur}
                            {...(!thisHideUserDtails && { nextSelector: this.firstNameSelector })}
                        />
                        <ErrorMessage name="deliveryDate">{msg => <div className={classes.feedback}>{msg}</div>}</ErrorMessage>
                    </Grid>
                    {
                        showMap &&
                        <Fragment>
                            <Grid item>
                                <Tooltip title="Would you like the driver to collect on delivery? This will incur additional charges." placement="right">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.isRoundTrip}
                                                onChange={handleChange}
                                                name="isRoundTrip"
                                                color="default"
                                                classes={{ checked: classes.collectChecked }}
                                            />
                                        }
                                        label="Collect on delivery?"
                                        labelPlacement='start'
                                        classes={{ root: classes.collectOnDeliveryRoot, labelPlacementStart: classes.collectPlacement, label: classes.collectOnDeliveryLabel }}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.deliveryMap}>
                                    <DeliveryMap
                                        originAddress={!isEmpty(values.originAddress) ? values.originAddress : null}
                                        destinationAddress={!isEmpty(values.destinationAddress) ? values.destinationAddress : null}
                                        territories={this.props.territories}
                                        nav={this.props.nav}
                                    />
                                </div>
                            </Grid>
                        </Fragment>
                    }
                </Grid >
                {
                    !thisHideUserDtails &&
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="firstName"
                                error={errors.firstName && touched.firstName}
                                success={!errors.firstName && touched.firstName}
                                fullWidth
                                InputProps={{
                                    style: { fontSize: "14px", marginBottom: "14px" },
                                    inputRef: this.firstNameSelector,
                                    autoComplete: "given-name",
                                    placeholder: "First name",
                                    type: "text",
                                    name: "firstName",
                                    value: values.firstName,
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className={classes.inputIconsColor}>face</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <ErrorMessage name="firstName">
                                {(msg) => <div className={classes.feedback}>{msg}</div>}
                            </ErrorMessage>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="lastName"
                                error={errors.lastName && touched.lastName}
                                success={!errors.lastName && touched.lastName}
                                fullWidth
                                InputProps={{
                                    style: { fontSize: "14px", marginBottom: "14px" },
                                    autoComplete: "family-name",
                                    placeholder: "Last name",
                                    type: "text",
                                    name: "lastName",
                                    value: values.lastName,
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon className={classes.inputIconsColor}>face</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <ErrorMessage name="lastName">
                                {(msg) => <div className={classes.feedback}>{msg}</div>}
                            </ErrorMessage>

                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                error={errors.email && touched.email}
                                success={!errors.email && touched.email}
                                fullWidth
                                InputProps={{
                                    style: { fontSize: "14px", marginBottom: "14px" },
                                    autoComplete: "email",
                                    placeholder: "Email",
                                    type: "email",
                                    name: "email",
                                    disabled: auth.isAuthenticated,
                                    value: values.email,
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailOutlined className={classes.inputIconsColor} />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <ErrorMessage name="email">
                                {(msg) => <div className={classes.feedback}>{msg}</div>}
                            </ErrorMessage>

                        </Grid>

                        <Grid item xs={12}>
                            <PhoneNumberPicker
                                contactCountryFieldName="contactCountryCode"
                                phoneNumberFieldName="phoneNumber"
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                            <ErrorMessage name="phoneNumber">{msg => <div className={classes.feedback}>{msg}</div>}</ErrorMessage>
                            <ErrorMessage name="contactCountryCode">{msg => <div className={classes.feedback}>{msg}</div>}</ErrorMessage>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    deliveryWindows: state.deliveryWindows,
    portal: state.portal,
    stores: state.stores,
    auth: state.auth,
    territories: state.territories,
    nav: state.nav
})

export default compose(
    withStyles(styles, { withTheme: true }, { name: 'LocationTimeForm' }),
    connect(mapStateToProps, { getAvailableDates, getAvailableTimeslots, getStores, setError, getTerritories, getStoresSelect })
)(LocationTimeForm);