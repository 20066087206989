import { GET_CONTACT_TYPES, GET_ERRORS } from './types';
import { API_CONTACT_TYPES } from '../_constants/apiConstants';
import createAxiosInstance from './_axiosConfig';

const apiContactTypesEndpoint = API_CONTACT_TYPES;

export const getContactTypes = () => dispatch => {
    return createAxiosInstance().then(axios => {
        return axios.get(apiContactTypesEndpoint)
            .then(res => {
                dispatch({
                    type: GET_CONTACT_TYPES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}