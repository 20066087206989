import { GET_COUNTRIES, GET_ERRORS } from './types';
import { API_COUNTRIES } from '../_constants/apiConstants';
import createAxiosInstance from './_axiosConfig';

const apiCountriesEndpoint = API_COUNTRIES;

export const getCountries = () => dispatch => {
    return createAxiosInstance().then(axios => {
        return axios.get(apiCountriesEndpoint)
            .then(res => {   
                dispatch({
                    type: GET_COUNTRIES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}