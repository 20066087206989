import { GET_STORES_SELECT } from "./types";
import createAxiosInstance from "./_axiosConfig";
import { API_STORES } from "../_constants/apiConstants";
import isEmpty from "../_utils/isEmpty";
import store from "../_utils/store";

export const getStoresSelect =
    (searchValue = "") =>
        (dispatch) => {
            if (!isEmpty(searchValue)) {
                searchValue = "&search=" + searchValue;
            }

            let portalCountryCode = store.getState().portal.data.cca2;

            return createAxiosInstance().then((axios) => {
                return axios.get(
                    `${API_STORES}/${portalCountryCode}?select=true${searchValue}`
                )
                    .then((res) => {
                        dispatch({
                            type: GET_STORES_SELECT,
                            payload: res.data,
                        });
                    })
                    .catch((err) => {
                        dispatch({
                            type: GET_STORES_SELECT,
                            payload: [],
                        });
                    });
            });
        };
