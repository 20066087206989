import store from '../_utils/store';
import buildQueryMeta from "../_utils/buildQueryMeta";
import buildPageMeta from "../_utils/buildPageMeta";
import serializeQuery from "../_utils/serializeQuery";
import { API_ELASTIC_USERS, API_ELASTIC_JOBS, API_ELASTIC_DELIVERIES, API_JOB_WORKFLOWS } from "../_constants/apiConstants";
import { USER_ID_FILTER, JOB_ID_FILTER } from "../_constants/selectConstants";
import { SET_JOB_DETAILS, GET_JOB_DETAILS_DRIVER, GET_ERRORS, GET_JOB_DETAILS, GET_JOB_DETAILS_DELIVERIES, CLEAR_JOB_DETAILS, GET_JOB_DETAILS_TRANSACTION_ITEMS, SET_LOADING_JOB_DETAILS_DELIVERIES } from "./types";
import isEmpty from '../_utils/isEmpty';
import createAxiosInstance from './_axiosConfig';

export const getJobDetails = (jobId) => dispatch => {
    let query = buildQueryMeta(buildPageMeta({}, [{ field: JOB_ID_FILTER, value: jobId }]));
    return createAxiosInstance().then(axios => {
    return axios.get(`${API_ELASTIC_JOBS}?${serializeQuery(query)}`)
        .then(res => {
            dispatch({
                type: GET_JOB_DETAILS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

export const getJobDetailsDriver = (driverId) => dispatch => {
    if (isEmpty(driverId) || driverId <= 0)
        return dispatch({ type: GET_JOB_DETAILS_DRIVER, payload: '' });

    let query = buildQueryMeta(buildPageMeta({}, [{ field: USER_ID_FILTER, value: driverId }]));
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.get(`${API_ELASTIC_USERS}/${portalCountryCode}?${serializeQuery(query)}`)
        .then(res => {
            dispatch({
                type: GET_JOB_DETAILS_DRIVER,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

export const getJobDetailsDeliveries = (jobId) => dispatch => {
    let query = buildQueryMeta(buildPageMeta({}, [{ field: "jobid", value: jobId }, { field: "deliveryStatus", value: "All" }], 1, 200));
    _setLoading(dispatch, true);
    return createAxiosInstance().then(axios => {
    return axios.get(`${API_ELASTIC_DELIVERIES}?${serializeQuery(query)}`)
        .then(res => {
            dispatch({
                type: GET_JOB_DETAILS_DELIVERIES,
                payload: res.data
            });
        })
        .catch(err => {
            _setLoading(dispatch, false);
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

export const getJobDetailsTransactionItems = (jobId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.get(`${API_JOB_WORKFLOWS}/getJobTransactionItems/${portalCountryCode}/${jobId}`)
        .then(res => {
            dispatch({
                type: GET_JOB_DETAILS_TRANSACTION_ITEMS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}


export const setJobDetails = (job) => dispatch => {
    return dispatch({
        type: SET_JOB_DETAILS,
        payload: job
    });
}

export const clearJobDetails = (reduxSubKey) => dispatch => {
    return dispatch({
        type: CLEAR_JOB_DETAILS,
        payload: reduxSubKey
    });
}

const _setLoading = (dispatch, loading = false) => {
    dispatch({
        type: SET_LOADING_JOB_DETAILS_DELIVERIES,
        payload: loading
    });
}
