import React, { Component } from 'react';
import StackTrace from "stacktrace-js";
import styles from './withAppInitializer.css';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import logo from '../../../logo.svg';
import ani1 from '../../../assets/img/Loading.svg';
import { compose } from 'react-recompose';
import { clearErrorsSuccess } from '../../../wumdrophubsreactshared/_actions/errors';
import { getCountryByCountryCode } from '../../../wumdrophubsreactshared/_actions/portal';
import setAxiosToken from '../../../wumdrophubsreactshared/_utils/setAxiosToken';
import { logoutUser, getUserPermissions, setPreviousUser, refreshToken } from '../../../wumdrophubsreactshared/_actions/authentication';
import { deliveryLoadingOff, setDeliveryWizardProps } from '../../../wumdrophubsreactshared/_actions/navigation';
import { processCardPayment } from '../../../wumdrophubsreactshared/_actions/quotes'
import ConnectionErrorCountdown from '../errors/ConnectionErrorCountdown';
import NotificationDialog from '../dialogs/NotificationDialog';
import URI from 'urijs'
import isEmpty from '../../../wumdrophubsreactshared/_utils/isEmpty';
import ReconnectButton from '../signal-r/ReconnectButton';
import ConnectButton from '../signal-r/ConnectButton';
import { CircularProgress } from '@material-ui/core';
import { ERROR_CONNECTION } from '../../../wumdrophubsreactshared/_constants/stringConstants';
import axios from 'axios';
import { API_BASE_URL, API_FRONTENDERRORS } from '../../../wumdrophubsreactshared/_constants/apiConstants';
import Button from '@material-ui/core/Button';
import AuthIdleTimerContainer from 'components/authentication/AuthIdleTimerContainer';
import grantAllPermissions from 'wumdrophubsreactshared/_utils/grantAllPermissions';
import hasPermission from 'wumdrophubsreactshared/_utils/hasPermission';
import getErrorTitleFromStatusCode from 'wumdrophubsreactshared/_utils/getErrorTitleFromStatusCode';
import { Alert } from '@material-ui/lab';
import isJwtTokenAboutToExpire from 'wumdrophubsreactshared/_utils/isJwtTokenAboutToExpire';
import isJwtTokenExpired from 'wumdrophubsreactshared/_utils/isJwtTokenExpired';

function GetCountryCode() {
  let uri = new URI(window.location.href);

  let hostNamePieces = uri.hostname().split('.')

  let firstPiece = hostNamePieces[0];

  if (firstPiece.includes("-")) {
    let firstPieces = firstPiece.split('-')
    return firstPieces[1];
  }
  else {
    return firstPiece;

  }

}

function LoadingMessage(classes, portal, component) {
  return (
    <div className={classes.splashScreen}>
      <div><img src={ani1} alt="Loading" height="140px" /></div>
      <div className={classes.logo}><img src={logo} alt="logo" height="33px" /></div>
      {component}
    </div>
  );
}

function AuthenticateUser(props) {
  if (localStorage.jwtToken && localStorage.jwtToken !== "null" && localStorage.jwtToken !== "undefined") {
    setAxiosToken(localStorage.jwtToken);
    //here we check if the user is currently impersonating another user (and update redux accordingly)
    if (localStorage.mainUser && localStorage.mainToken) {
      let previousUser = JSON.parse(localStorage.mainUser);
      props.setPreviousUser(previousUser);
    }

    let tokenExpired = isJwtTokenExpired(localStorage.jwtToken);

    if (isJwtTokenAboutToExpire(localStorage.jwtToken) && !tokenExpired) {
      props.refreshToken();
    } else if (tokenExpired) {
      props.logoutUser(() => window.location.reload());
    }
    return props.getUserPermissions(localStorage.jwtToken);
  }
  else {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }
}

function withAppInitializer(App) {
  const AppInitializer = class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true,
        isPaymentProcessing: false,
        errorMessage: '',
        errorTitle: '',
        errorAlertOpen: false,
        hasError: false,
        errorId: null,
        isDisabled: false
      };

    }

    handleReconnect = () => {
      window.location.reload();
    }

    handleCloseGlobalErrorDialog = () => {
      this.setState({ errorAlertOpen: false, errorMessage: '', errorTitle: '' });
      this.props.clearErrorsSuccess();
      if (this.props.deliveryLoadingOff) {
        this.props.deliveryLoadingOff(this.props.nav.deliveriesLoading);
      }
    }

    isPaymentProcessing = (id, deliveryId, resourcePath) => {
      return !isEmpty(id) && !isEmpty(deliveryId) && !isEmpty(resourcePath);
    }


    async componentDidMount() {
      const { getCountryByCountryCode } = this.props;
      const query = new URLSearchParams(window.location.search);

      this.cleanUpIndex();

      let countryCode = GetCountryCode();

      const id = query.get('id');
      const deliveryId = query.get('deliveryid');
      const resourcePath = query.get('resourcePath');
      const saveCard = localStorage.getItem(`wum_persist_${deliveryId}`);
      //check state jere
      let processing = this.isPaymentProcessing(id, deliveryId, saveCard, resourcePath);

      this.setState({
        loading: !processing,
        isPaymentProcessing: processing
      }, function () {
        AuthenticateUser(this.props).then(() => {
          getCountryByCountryCode(countryCode).then(() => {
            this.setState({
              loading: false,
            }, function () {
              if (this.state.isPaymentProcessing) {
                //process and load quote
                this.props.processCardPayment({ id: id, deliveryId: deliveryId, saveCard: saveCard, resourcePath: resourcePath }).then(() => {
                  //open quote dialogue
                  this.props.setDeliveryWizardProps(true, false, 5).then(() => {
                    this.setState({
                      isPaymentProcessing: false
                    }, function () {

                    });
                  });
                });

                //reload the quote form with updated quote state
                //set is paymentprocessing to false 

              }
            });
          });
        });
      });
    }

    async componentDidUpdate(prevProps, prevState) {
      //refresh the users JWT auth token if it has been changed by an admin user
      if (this.props.auth.isAuthenticated && prevProps.auth.isAuthenticated && !isEmpty(prevProps.auth.currentUser) && !isEmpty(this.props.auth.currentUser)) {
        if (!isEmpty(prevProps.auth.currentUser.user) && !isEmpty(this.props.auth.currentUser.user)) {
          if (prevProps.auth.currentUser.user.refresh === false && this.props.auth.currentUser.user.refresh === true) {
            this.props.refreshToken();
          }

          // if the user active changes from false to true reload the route 
          // if they are not on a publicly accessible page
          // the router will automatically route them to the forbidden page
          if (prevProps.auth.currentUser.user.isActive && !this.props.auth.currentUser.user.isActive) {
            window.location.reload();
          }
        }
      }

      //global error dialog for errors coming from backend
      if (!isEmpty(this.props.errors.success) && this.props.errors.success !== prevProps.errors.success) {
        let title = getErrorTitleFromStatusCode(this.props.errors.status);
        let message = this.props.errors.message;
        this.setState({ errorTitle: title, errorMessage: message, errorAlertOpen: true })
      }
    }



    async componentDidCatch(error, errorInfo) {
      try {
        await StackTrace.fromError(error).then(async stack => {
          await axios.post(API_BASE_URL + API_FRONTENDERRORS,
            {
              Error: error.toString(),
              Url: window.location.href,
              Agent: window.navigator.userAgent,
              Stack: stack,
              Auth: !isEmpty(this.props.auth) ? JSON.stringify(this.props.auth) : null
            }
          )
            .then(res => {
              this.setState({ hasError: true, errorId: res.data });
            })
            .catch(err => {
              this.setState({ hasError: true, errorId: null });
            });
        });

      }
      catch {
        this.setState({ hasError: true, errorId: null });
      }
    }

    cleanUpIndex = () => {
      let element = document.getElementById("mainBody");
      element.classList.remove("loadingBackground");
    }


    logOutUser = () => {
      this.setState({ loading: true });
      this.props.logoutUser(() => {
        window.location.href = '/login';
      });
    }

    render() {
      const { classes, errors, portal, auth } = this.props;
      const { connected, wasConnected } = this.props.signalRSubscriptions;
      const { errorMessage, errorTitle, errorAlertOpen, isPaymentProcessing, hasError, errorId } = this.state;

      if (errors.isSessionError) {
        return LoadingMessage(classes, portal,
          <div className={classes.loadingBox}>
            <div className={classes.loadingText}>Your session is no longer valid. Please logout and sign-in again.</div>
            <Button variant="contained" size="small" color="secondary" onClick={this.logOutUser}>
              Logout
            </Button>
          </div>
        );
      } else if (errors.isConnectionError || errors.forceConnectionError) {
        return LoadingMessage(classes, portal,
          <ConnectionErrorCountdown>{ERROR_CONNECTION}</ConnectionErrorCountdown>
        );
      }

      if (hasError) return LoadingMessage(classes, portal,
        <ConnectionErrorCountdown>{!isEmpty(errorId) ? `${errorId} - An error has occured and will be fixed soon!. Trying again in` : "Oops! An error has occured and we were unable to log it! Trying again in"}</ConnectionErrorCountdown>
      );


      if (isPaymentProcessing)
        return LoadingMessage(classes, portal,
          <div className={classes.loadingBox}>
            <CircularProgress className={classes.loadingCircle} />
            <div className={classes.loadingText}>Processing payment ...</div>
          </div>
        );

      // while checking user session, show "loading" message
      if (this.state.loading)
        return LoadingMessage(classes, portal,
          <div className={classes.loadingBox}>
            <CircularProgress className={classes.loadingCircle} />
            <div className={classes.loadingText}>Loading ...</div>
          </div>
        );

      if (isEmpty(portal.data) || Object.keys(portal.data).length === 0)
        return LoadingMessage(classes, portal,
          <div className={classes.loadingBox}>
            <div className={classes.loadingText}>Unknown host ...</div>
          </div>
        );

      // otherwise, show the desired route
      return (
        /* here we pass in our various global error components to our wrapped App component so that theme overrides (in App.js) are applied */
        <App>
          {!connected && wasConnected && hasPermission(auth, { ...grantAllPermissions }) &&
            <ReconnectButton onRefresh={this.handleReconnect} message={"Connection lost. Trying to reconnect in: "} />}

          {!connected && !wasConnected && hasPermission(auth, { ...grantAllPermissions }) &&
            <ConnectButton onRefresh={this.handleReconnect} message={"Connecting to server..."} />}

          {!errors.isConnectionError &&
            <NotificationDialog
              open={errorAlertOpen}
              title={errorTitle}
              severity="error"
              onCloseClick={this.handleCloseGlobalErrorDialog}
            >
              <Alert severity="error">
                {errorMessage}
              </Alert>

            </NotificationDialog>
          }

          <AuthIdleTimerContainer onTimeout={this.logOutUser} auth={auth} />
        </App>
      )
    }
  };

  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    portal: state.portal,
    quotes: state.quotes,
    signalRSubscriptions: state.signalRSubscriptions,
    nav: state.nav
  })

  return compose(
    withStyles(styles, { name: 'AppInitializer' }),
    connect(mapStateToProps, { setDeliveryWizardProps, processCardPayment, logoutUser, clearErrorsSuccess, getCountryByCountryCode, getUserPermissions, setPreviousUser, refreshToken, deliveryLoadingOff })
  )(AppInitializer);
}

export default withAppInitializer;