import {
    GET_ERRORS,
    CLEAR_TRANSACTION_ITEMS,
    GET_TRANSACTION_ITEMS,
    CLEAR_TRANSACTION_ITEMS_SUCCESS,
    POST_TRANSACTION_ITEMS,
    SET_LOADING_TRANSACTION_ITEMS,
} from "./types";
import createAxiosInstance from "./_axiosConfig";
import {
    API_TRANSACTIONS,
    API_BYTES_RESPONSE,
} from "../_constants/apiConstants";
import buildPageMeta from "../_utils/buildPageMeta";
import buildResponseMeta from "../_utils/buildResponseMeta";
import store from "../_utils/store";
import buildQueryMeta from "../_utils/buildQueryMeta";
import serializeQuery from "../_utils/serializeQuery";
import {
    TRANSACTION_ITEMS_NAME,
    EXCEL_TYPE,
} from "../_constants/excelConstants";

export const getTransactionItemsPage =
    (transactionId, meta = {}) =>
        (dispatch) => {
            return _getPage(transactionId, meta, dispatch);
        };

export const exportTransactionItems =
    (transactionId, meta, downloadFunction = null) =>
        (dispatch) => {
            if (downloadFunction) {
                let query = buildQueryMeta(meta);
                let portalCountryCode = store.getState().portal.data.cca2;
                return createAxiosInstance().then((axios) => {
                    return axios.get(`${API_TRANSACTIONS}/${portalCountryCode}/${transactionId}/transactionItems?${serializeQuery(
                            query
                        )}`,
                        API_BYTES_RESPONSE
                    )
                        .then((res) => {
                            let blob = new Blob([res.data], EXCEL_TYPE);
                            downloadFunction(
                                blob,
                                `Transaction Id ${transactionId} ${TRANSACTION_ITEMS_NAME}`
                            );
                        })
                        .catch((err) => {
                            dispatch({
                                type: GET_ERRORS,
                                payload: err.response,
                            });
                        });
                });
            }
        };

export const createJobTransactionItem = (jobTransactionItem) => (dispatch) => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // turn loading on
    _setLoading(dispatch, true);

    return createAxiosInstance().then((axios) => {
        return axios.post(`${API_TRANSACTIONS}/createJobTransactionItem/${portalCountryCode}`,
            jobTransactionItem
        )
            .then((res) => {
                // loading gets turned off here
                dispatch({
                    type: POST_TRANSACTION_ITEMS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });

                // turn loading off
                _setLoading(dispatch, false);
            });
    });
};

export const createDeliveryTransactionItem =
    (deliveryTransactionItem) => (dispatch) => {
        let portalCountryCode = store.getState().portal.data.cca2;

        // turn loading on
        _setLoading(dispatch, true);

        return createAxiosInstance().then((axios) => {
            return axios.post(
                `${API_TRANSACTIONS}/createDeliveryTransactionItem/${portalCountryCode}`,
                deliveryTransactionItem
            )
                .then((res) => {
                    // loading gets turned off here
                    dispatch({
                        type: POST_TRANSACTION_ITEMS,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response,
                    });

                    // turn loading off
                    _setLoading(dispatch, false);
                });
        });
    };

export const clearTransactionItems = () => (dispatch) => {
    dispatch({
        type: CLEAR_TRANSACTION_ITEMS,
        payload: "",
    });
};

export const clearTransactionItemsSuccess = () => (dispatch) => {
    dispatch({
        type: CLEAR_TRANSACTION_ITEMS_SUCCESS,
        payload: "",
    });
};

let _getPage = (transactionId, meta, dispatch) => {
    let query = buildQueryMeta(meta);
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then((axios) => {
        return axios.get(`${API_TRANSACTIONS}/${portalCountryCode}/${transactionId}/transactionItems?${serializeQuery(
                query
            )}`
        )
            .then((res) => {
                res = buildResponseMeta(res, buildPageMeta());
                dispatch({
                    type: GET_TRANSACTION_ITEMS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response,
                });
            });
    });
};

/** Helper functions **/
const _setLoading = (dispatch, loading = false) => {
    dispatch({
        type: SET_LOADING_TRANSACTION_ITEMS,
        payload: loading,
    });
};
