import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';
import {
  SET_CURRENT_USER,
  CONNECT_TO_SIGNALR,
  DISCONNECT_FROM_SIGNALR,
  SUBSCRIBE_TO_COORDINATE_UPDATES,
  UNSUBSCRIBE_FROM_COORDINATE_UPDATES,
  RESUBSCRIBE_TO_COORDINATE_UPDATES,
  SUBSCRIBE_TO_JOB_TASK_UPDATES,
  UNSUBSCRIBE_FROM_JOB_TASK_UPDATES,
  RESUBSCRIBE_TO_JOB_TASK_UPDATES,
  UPDATE_DRIVER_LOCATIONS,
  UNSUBSCRIBE_FROM_DELIVERIES_GROUP,
  SUBSCRIBE_TO_DELIVERIES_GROUP,
  SUBSCRIBE_TO_JOBS_GROUP,
  UNSUBSCRIBE_FROM_JOBS_GROUP,
  SUBSCRIBE_TO_USERS_GROUP,
  UNSUBSCRIBE_FROM_USERS_GROUP,
  SUBSCRIBE_TO_BULKINDEX_GROUP,
  UNSUBSCRIBE_FROM_BULKINDEX_GROUP,
  SUBSCRIBE_TO_WEB_NOTIFICATIONS_GROUP,
  UNSUBSCRIBE_FROM_WEB_NOTIFICATIONS_GROUP,
  UPDATE_BULK_INDEXER_TERMINAL,
  UPDATE_DELIVERIES,
  UPDATE_JOBS,
  UPDATE_USERS,
  UPDATE_JOB_TASKS,
  NEW_DELIVERIES,
  NEW_JOBS,
  NEW_USERS,
  UPDATE_USER_CONTEXT,
  REFRESH_DRIVER_LIST_ON,
  RELOAD_QUOTE,
  LOGOUT
} from '../_actions/types';
import { BEACON_DASHBOARD, MAX_RETRYS, RETRY_CONNECTION_INTERVAL_MILLIS, UPDATE_INTERVAL_MILLIS } from '../_constants/beaconConstants';
import { BEACON_MESSAGE_TYPES, ENTITY_STATE_ENUMS } from '../_constants/apiConstants';
import isEmpty from '../_utils/isEmpty';
import { getBaseBeaconUrlAsync } from '../_utils/getBaseBeaconUrlAsync';
import { _jobLoadingOff, _deliveryLoadingOff } from '../_actions/navigation';
import { getTotalItemCount, setPlay } from '../_actions/webNotifications';
import { getJwtToken } from '../../_tokenService';
import isJwtTokenAboutToExpire from '../_utils/isJwtTokenAboutToExpire';
import refreshAccessToken from '../_utils/refreshAccessToken';
import isJwtTokenExpired from '../_utils/isJwtTokenExpired';
import grantAllPermissions from '../_utils/grantAllPermissions';
import hasPermission from '../_utils/hasPermission';
let connection = null; //this is our signal R connection we subscribe to and unsubscribe from
let driverLocationsQueue = new Map(); // this is our queue for applying coordinate updates
let deliveriesUpdateQueue = new Map(); // this is our queue for applying delivery updates
let jobsUpdateQueue = new Map(); // this is our queue for applying job updates
let driversUpdateQueue = new Map(); // this is our queue for applying driver updates
let jobTaskUpdatesQueue = new Map(); // this is our queue for task job updates

let deliveriesNewQueue = new Set(); // this is our queue for applying new delivery indicator on the dash
let jobsNewQueue = new Set(); // this is our queue for applying new job indicator on the dash
let driversNewQueue = new Set(); // this is our queue for applying new driver indicator on the dash

let bulkIndexerTerminalQueue = new Set();


let reconnectTimer = 0; //this is our interval for trying to reconnect to signal R if we are unable to establish a connection


const signalRMiddleware = ({ dispatch, getState }) => next => async (action) => {

  switch (action.type) {
    case SET_CURRENT_USER: //initialise signalR connection when a user logs in
      initSignalR(dispatch, getState);
      break;

    case SUBSCRIBE_TO_COORDINATE_UPDATES:
      subscribeToCoordinateUpdates(action.payload, dispatch);
      break;

    case UNSUBSCRIBE_FROM_COORDINATE_UPDATES:
      unsubscribeFromCoordinateUpdates(action.payload, dispatch);
      break;

    case RESUBSCRIBE_TO_COORDINATE_UPDATES:
      reSubscribeToCoordinateUpdates(action.payload, dispatch);
      break;

    case SUBSCRIBE_TO_JOB_TASK_UPDATES:
      subscribeToJobTaskUpdates(action.payload, dispatch);
      break;

    case UNSUBSCRIBE_FROM_JOB_TASK_UPDATES:
      unsubscribeFromJobTaskUpdates(action.payload, dispatch);
      break;

    case RESUBSCRIBE_TO_JOB_TASK_UPDATES:
      reSubscribeToJobTaskUpdates(action.payload, dispatch);
      break;

    case SUBSCRIBE_TO_DELIVERIES_GROUP:
      subscribeToDeliveriesGroup(action.payload, dispatch);
      break;

    case UNSUBSCRIBE_FROM_DELIVERIES_GROUP:
      unsubscribeFromDeliveriesGroup(action.payload, dispatch);
      break;

    case SUBSCRIBE_TO_JOBS_GROUP:
      subscribeToJobsGroup(action.payload, dispatch);
      break;

    case UNSUBSCRIBE_FROM_JOBS_GROUP:
      unsubscribeFromJobsGroup(action.payload, dispatch);
      break;

    case SUBSCRIBE_TO_USERS_GROUP:
      subscribeToUsersGroup(action.payload, dispatch);
      break;

    case UNSUBSCRIBE_FROM_USERS_GROUP:
      unsubscribeFromUsersGroup(action.payload, dispatch);
      break;
    case SUBSCRIBE_TO_BULKINDEX_GROUP:
      subscribeToBulkIndexerGroup(action.payload, dispatch);
      break;
    case UNSUBSCRIBE_FROM_BULKINDEX_GROUP:
      unsubscribeFromBulkIndexerGroup(action.payload, dispatch);
      break;
    case SUBSCRIBE_TO_WEB_NOTIFICATIONS_GROUP:
      subscribeToWebNotificationGroup(action.payload, dispatch);
      break;
    case UNSUBSCRIBE_FROM_WEB_NOTIFICATIONS_GROUP:
      unsubscribeFromWebNotificationGroup(action.payload, dispatch);
      break;
    case LOGOUT: // remember to disconnect from signal R on logout
      stopSignalRConnection(dispatch);
      break;
    default:
      break;
  }

  return next(action);
};

const getAccessToken = async () => {
  let jwtToken = await getJwtToken();
  if (isJwtTokenAboutToExpire(jwtToken) && !isJwtTokenExpired(jwtToken)) {
    console.debug(">>>>> SIGNALR REFRESHING TOKEN <<<<<<");
    let response = await refreshAccessToken();
    return response.data.jwtToken;
  }
  return jwtToken;
};

const handleSignalRConnectionClosed = (dispatch, getState) => {

  // only try reconnect if the user is logged in
  let auth = getState().auth;
  if (hasPermission(auth, { ...grantAllPermissions })) {
    //let redux know signalR connection has dropped
    dispatch({
      type: DISCONNECT_FROM_SIGNALR,
      payload: false
    });

    connection.stop()
      .then(() => console.info('Dashboard SignalR connection stopped.'))
      .catch(err => console.error('Error while stopping Dashboard SingalR connection :( ' + err));

    clearInterval(reconnectTimer);

    //try to connect again if signalR is still not connected
    let i = 0;
    reconnectTimer = setInterval(() => {
      i++;
      if (i < MAX_RETRYS && !getState().signalRSubscriptions.connected) {
        console.info('Trying to connect to Signal R')

        //try to reconnect again
        connection.start()
          .then(() => {
            setSignalRConnected(dispatch, getState);
          })
          .catch(err => {
            console.error('SignalR Connection Error: ', err);
          });

      }
    }, RETRY_CONNECTION_INTERVAL_MILLIS);
  }
}

const initSignalR = (dispatch, getState) => {

  getBaseBeaconUrlAsync().then((baseUrl) => {
    //setup our signal r beacon for the dashboard
    let dashboardBeaconUrl = encodeURI(baseUrl + BEACON_DASHBOARD);

    const protocol = new JsonHubProtocol();
    const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;
    const options = {
      transport,
      logMessageContent: true,
      logger: LogLevel.Critical,
      accessTokenFactory: () => getAccessToken()
    };

    // create the connection instance
    connection = new HubConnectionBuilder()
      .withUrl(dashboardBeaconUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect()
      .build();

    // re-establish the connection if connection dropped
    connection.onclose(() => handleSignalRConnectionClosed(dispatch, getState));

    startSignalRConnection(dispatch, getState);

    //process our queues for changes to entities on the dash every x milliseconds
    setInterval(() => processQueues(dispatch, getState), UPDATE_INTERVAL_MILLIS);
  });

}

const startSignalRConnection = (dispatch, getState) => {
  if (connection) {
    connection.start()
      .then(() => {
        setSignalRConnected(dispatch, getState);
      })
      .catch(err => {
        handleSignalRConnectionClosed(dispatch, getState);
      });
  }
}

const stopSignalRConnection = (dispatch) => {
  if (connection) {
    connection.stop()
      .then(() => {
        //let redux know signalR connection has dropped
        dispatch({
          type: DISCONNECT_FROM_SIGNALR,
          payload: false
        });
        console.info('SignalR connection stopped.');
      })
      .catch(err => console.error('Error while stopping Dashboard SingalR connection :( ' + err));
  }
}

const setSignalRConnected = (dispatch, getState) => {
  console.info('SignalR Connected');
  dispatch({
    type: CONNECT_TO_SIGNALR,
    payload: true
  });

  connection.on(BEACON_MESSAGE_TYPES.REFRESH_DRIVER_LIST_AUTH, (driverId) => {
    dispatch({
      type: REFRESH_DRIVER_LIST_ON
    });
  });

  //here we listen for user permission changes from the backend
  connection.on(BEACON_MESSAGE_TYPES.USER_PERMISSIONS_UPDATE, (userId, userPermissions) => {
    let auth = getState().auth;
    let currentUser = !isEmpty(auth.currentUser) && !isEmpty(auth.currentUser.user) ? auth.currentUser.user : null;

    if (currentUser && userId === currentUser.id) {
      dispatch({
        type: UPDATE_USER_CONTEXT,
        payload: userPermissions
      });
    }
  });
}

const subscribeToCoordinateUpdates = (driverIds, dispatch) => {
  if (connection) {
    connection.invoke('subscribeToCoordinateUpdates', driverIds)
      .then((value) => {
        console.info(`Invocation of SubscribeToCoordinateUpdates succeeded ${value}.`);
      })
      .catch((error) => {
        console.info('Invocation of SubscribeToCoordinateUpdates failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.COORDINATE_UPDATES, (userId, coordinates) => {
      //here we key on string "#{userId}" to ensure ordering is not lost while maintaining O(1) lookup
      let idString = `#${userId}`;
      driverLocationsQueue.set(idString, coordinates);
    });

    connection.on(BEACON_MESSAGE_TYPES.REFRESH_DRIVER_LIST, (driverId) => {
      dispatch({
        type: REFRESH_DRIVER_LIST_ON
      });
    });
  }
}

//payload looks like the following:
//paload = {
//   currentIds: [Array of ids to unsubscribe from],
//   newIds: [Array of new ids to subscrube to]
// }
const reSubscribeToCoordinateUpdates = (payload, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromCoordinateUpdates', payload.currentIds)
      .then((value) => {
        console.info('Invocation of UnsubscribeFromCoordinateUpdates succeeded ' + value);
        connection.invoke('subscribeToCoordinateUpdates', payload.newIds)
          .then((value) => {
            console.info(`Invocation of SubscribeToCoordinateUpdates succeeded ${value}.`);
          })
          .catch((error) => {
            console.info('Invocation of SubscribeToCoordinateUpdates failed. Error: ' + error);
          });
      })
      .catch((error) => {
        console.info('Invocation of UnsubscribeFromCoordinateUpdates failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.COORDINATE_UPDATES, (userId, coordinates) => {
      //here we key on string "#{userId}" to ensure ordering is not lost while maintaining O(1) lookup
      let idString = `#${userId}`;
      driverLocationsQueue.set(idString, coordinates);
    });
  }
}


const unsubscribeFromCoordinateUpdates = (driverIds, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromCoordinateUpdates', driverIds)
      .then((value) => {
        console.info('Invocation of UnsubscribeFromCoordinateUpdates succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of UnsubscribeFromCoordinateUpdates failed. Error: ' + error);
      });
  }
}

const subscribeToJobTaskUpdates = (jobIds, dispatch) => {
  if (connection) {
    connection.invoke('subscribeToJobTaskUpdates', jobIds)
      .then((value) => {
        console.info(`Invocation of SubscribeToJobTaskUpdates succeeded ${value}.`);
      })
      .catch((error) => {
        console.info('Invocation of SubscribeToJobTaskUpdates failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.JOB_TASK_UPDATES, (taskId, taskState, task) => {
      //here we key on string "#{userId}" to ensure ordering is not lost while maintaining O(1) lookup
      let idString = `#${taskId}`;
      jobTaskUpdatesQueue.set(idString, task);
    });
  }
}

//payload looks like the following:
//paload = {
//   currentIds: [Array of ids to unsubscribe from],
//   newIds: [Array of new ids to subscrube to]
// }
const reSubscribeToJobTaskUpdates = (payload, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromJobTaskUpdates', payload.currentIds)
      .then((value) => {
        console.info('Invocation of UnsubscribeFromJobTaskUpdates succeeded ' + value);
        connection.invoke('subscribeToJobTaskUpdates', payload.newIds)
          .then((value) => {
            console.info(`Invocation of SubscribeToJobTaskUpdates succeeded ${value}.`);
          })
          .catch((error) => {
            console.info('Invocation of SubscribeToJobTaskUpdates failed. Error: ' + error);
          });
      })
      .catch((error) => {
        console.info('Invocation of UnsubscribeFromJobTaskUpdates failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.JOB_TASK_UPDATES, (taskId, taskState, task) => {
      //here we key on string "#{userId}" to ensure ordering is not lost while maintaining O(1) lookup
      let idString = `#${taskId}`;
      jobTaskUpdatesQueue.set(idString, task);
    });
  }
}


const unsubscribeFromJobTaskUpdates = (driverIds, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromJobTaskUpdates', driverIds)
      .then((value) => {
        console.info('Invocation of UnsubscribeFromJobTaskUpdates succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of UnsubscribeFromJobTaskUpdates failed. Error: ' + error);
      });
  }
}

const subscribeToDeliveriesGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('subscribeToDeliveries')
      .then((value) => {
        console.info('Invocation of SubscribeToDeliveries succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of SubscribeToDeliveries failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.SEND_DELIVERIES, (indexId, entityId, entityState, deserializedDocument, xmin) => {

      //here we key our queue on string "#{entityId}" to ensure ordering is not lost while maintaining O(1) lookup
      let stringId = `#${entityId}`;

      switch (entityState) {
        case ENTITY_STATE_ENUMS.NEW:
          //new entity (i.e., create)
          deliveriesNewQueue.add(stringId);
          break;

        case ENTITY_STATE_ENUMS.EXISTING:
          //existing entity (i.e., update)
          deliveriesUpdateQueue.set(stringId, deserializedDocument);
          break;

        default:
          //do nothing
          break;
      }

      //reload any open quotes
      dispatch({
        type: RELOAD_QUOTE,
        payload: { entityId, xmin }
      });

    });
  }
}

const unsubscribeFromDeliveriesGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromDeliveries')
      .then((value) => {
        console.info('Invocation of UnsubscribeFromDeliveries succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of UnsubscribeFromDeliveries failed. Error: ' + error);
      });
  }
}

const subscribeToJobsGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('subscribeToJobs')
      .then((value) => {
        console.info('Invocation of SubscribeToJobs succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of SubscribeToJobs failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.SEND_JOBS, (indexId, entityId, entityState, deserializedDocument) => {

      //here we key our queue on string "#{entityId}" to ensure ordering is not lost while maintaining O(1) lookup
      let stringId = `#${entityId}`;

      switch (entityState) {
        case ENTITY_STATE_ENUMS.NEW:
          //new entity (i.e., create)
          jobsNewQueue.add(stringId);
          break;

        case ENTITY_STATE_ENUMS.EXISTING:
          //existing entity (i.e., update)
          jobsUpdateQueue.set(stringId, deserializedDocument);
          break;

        default:
          //do nothing
          break;
      }
    });
  }
}

const unsubscribeFromJobsGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromJobs')
      .then((value) => {
        console.info('Invocation of UnsubscribeFromJobs succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of UnsubscribeFromJobs failed. Error: ' + error);
      });
  }
}

const subscribeToUsersGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('subscribeToUsers')
      .then((value) => {
        console.info('Invocation of SubscribeToUsers succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of SubscribeToUsers failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.SEND_USERS, (indexId, entityId, entityState, deserializedDocument) => {

      //here we key our queue on string "#{entityId}" to ensure ordering is not lost while maintaining O(1) lookup
      let stringId = `#${entityId}`;

      //let currentUser = getState().auth.currentUser.user;
      if (!isEmpty(deserializedDocument) && !isEmpty(deserializedDocument.userRoles) && deserializedDocument.userRoles.includes("Driver")) {
        //a driver has changed, update teh drivers queue accordingly
        switch (entityState) {
          case ENTITY_STATE_ENUMS.NEW:
            //new entity (i.e., create)
            driversNewQueue.add(stringId);
            break;

          case ENTITY_STATE_ENUMS.EXISTING:
            //existing entity (i.e., update)
            driversUpdateQueue.set(stringId, deserializedDocument);
            break;

          default:
            //do nothing
            break;
        }
      }
    });
  }
}

const unsubscribeFromUsersGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromUsers')
      .then((value) => {
        console.info('Invocation of UnsubscribeFromUsers succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of UnsubscribeFromUsers failed. Error: ' + error);
      });
  }
}

const subscribeToBulkIndexerGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('subscribeToBulkIndexer')
      .then((value) => {
        console.info('Invocation of subscribeToBulkIndexer succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of subscribeToBulkIndexer failed. Error: ' + error);
      });

    connection.on(BEACON_MESSAGE_TYPES.BULK_INDEXER_PONG, (value) => {

      bulkIndexerTerminalQueue.add(value);


    });
  }
}

const unsubscribeFromBulkIndexerGroup = (payload, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromBulkIndexer')
      .then((value) => {
        console.info('Invocation of unsubscribeFromBulkIndexer succeeded ' + value);
      })
      .catch((error) => {
        console.info('Invocation of unsubscribeFromBulkIndexer failed. Error: ' + error);
      });
  }
}

const subscribeToWebNotificationGroup = (webNotificationTypeIds, dispatch) => {
  if (connection) {
    connection.invoke('subscribeToWebNotifications', webNotificationTypeIds)
      .then((value) => {
        console.info(`Invocation of subscribeToWebNotifications succeeded ${value}. WebNotificationTypeIds: ${webNotificationTypeIds}`);
      })
      .catch((error) => {
        console.info(`Invocation of subscribeToWebNotifications failed. Error: ${error}. WebNotificationTypeIds: ${webNotificationTypeIds}`);
      });

    connection.on(BEACON_MESSAGE_TYPES.WEB_NOTIFICATION, (value) => {

      dispatch(getTotalItemCount(webNotificationTypeIds)).then(() => {
        if (value.playSound && !value.read) {
          dispatch(setPlay(true));
        }
      });
    });
  }
}

const unsubscribeFromWebNotificationGroup = (webNotificationTypeIds, dispatch) => {
  if (connection) {
    connection.invoke('unsubscribeFromWebNotifications', webNotificationTypeIds)
      .then((value) => {
        console.info(`Invocation of UnsubscribeFromWebNotifications succeeded ${value}. WebNotificationTypeIds: ${webNotificationTypeIds}`);
      })
      .catch((error) => {
        console.info(`Invocation of UnsubscribeFromWebNotifications failed. Error: ${error}. WebNotificationTypeIds: ${webNotificationTypeIds}`);
      });
  }
}

const processQueues = (dispatch, getState) => {
  //update the main entities in redux with data collected from signal R
  updateDriverLocationsInRedux(dispatch);
  updateDeliveriesInRedux(dispatch, getState);
  updateJobsInRedux(dispatch, getState);
  updateUsersInRedux(dispatch);
  updateBulkIndexerQueue(dispatch);
  updateJobTaskUpdatesInRedux(dispatch);
}

const updateBulkIndexerQueue = (dispatch) => {

  if (bulkIndexerTerminalQueue.size > 0) {


    dispatch({
      type: UPDATE_BULK_INDEXER_TERMINAL,
      payload: bulkIndexerTerminalQueue
    });

    bulkIndexerTerminalQueue.clear();
  }
}

const updateDriverLocationsInRedux = dispatch => {
  if (driverLocationsQueue.size > 0) {

    dispatch({
      type: UPDATE_DRIVER_LOCATIONS,
      payload: driverLocationsQueue
    });

    driverLocationsQueue.clear();
  }
}

const updateJobTaskUpdatesInRedux = dispatch => {
  if (jobTaskUpdatesQueue.size > 0) {

    dispatch({
      type: UPDATE_JOB_TASKS,
      payload: jobTaskUpdatesQueue
    });

    jobTaskUpdatesQueue.clear();
  }
}

const updateDeliveriesInRedux = (dispatch, getState) => {

  //process our updates
  if (deliveriesUpdateQueue.size > 0) {

    dispatch({
      type: UPDATE_DELIVERIES,
      payload: deliveriesUpdateQueue
    });

    updateDeliveryLoaders(dispatch, getState);

    deliveriesUpdateQueue.clear();
  }

  //process our creates
  if (deliveriesNewQueue.size > 0) {

    dispatch({
      type: NEW_DELIVERIES,
      payload: deliveriesNewQueue
    });

    deliveriesNewQueue.clear();
  }
}

const updateJobsInRedux = (dispatch, getState) => {

  //process our updates
  if (jobsUpdateQueue.size > 0) {

    dispatch({
      type: UPDATE_JOBS,
      payload: jobsUpdateQueue
    });

    //here we remove any jobs that have finished loading due to receiving latest updates from signal R
    updateJobLoaders(dispatch, getState);

    jobsUpdateQueue.clear();
  }

  //process our creates
  if (jobsNewQueue.size > 0) {

    dispatch({
      type: NEW_JOBS,
      payload: jobsNewQueue
    });

    jobsNewQueue.clear();
  }
}

const updateUsersInRedux = dispatch => {
  if (driversUpdateQueue.size > 0) {

    dispatch({
      type: UPDATE_USERS,
      payload: driversUpdateQueue
    });

    driversUpdateQueue.clear();
  }

  //process our creates
  if (driversNewQueue.size > 0) {

    dispatch({
      type: NEW_USERS,
      payload: driversNewQueue
    });

    driversNewQueue.clear();
  }
}

//here we turn off any deliveries that are no longer loading due to us receiving their new updates via signal R
const updateDeliveryLoaders = (dispatch, getState) => {
  //loop through each of our deliveries that are set to loading
  //check if we've received the latest data from signal R
  //and remove the delivery from the nav.deliveriesLoading key in redux
  let reduxState = getState();
  if (reduxState.nav.deliveriesLoading.length > 0) {
    let loadingOffIds = [];
    reduxState.nav.deliveriesLoading.forEach(deliveryId => {
      if (deliveriesUpdateQueue.has(`#${deliveryId}`)) { //our update queue is keyed as a string on #{entityId}
        loadingOffIds.push(deliveryId);
      }
    });
    _deliveryLoadingOff(loadingOffIds, dispatch);
  }

}

//here we turn off any jobs that have finished loading due to receiving latest updates from signal R
const updateJobLoaders = (dispatch, getState) => {
  //loop through each of our jobs that are set to loading
  //check if we've received the latest data from signal R
  //and remove the delivery from the nav.jobsLoading key in redux
  let reduxState = getState();
  if (reduxState.nav.jobsLoading.length > 0) {
    let loadingOffIds = [];
    reduxState.nav.jobsLoading.forEach(jobId => {
      if (jobsUpdateQueue.has(`#${jobId}`)) { //our update queue is keyed as a string on #{entityId}
        loadingOffIds.push(jobId);
      }
    });
    _jobLoadingOff(loadingOffIds, dispatch);
  }
}

export default signalRMiddleware;