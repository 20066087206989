import { 
    GET_DELIVERIES, GET_ERRORS, POST_DELIVERIES, PUT_DELIVERIES, DELETE_DELIVERIES, 
    CLEAR_DELIVERIES, GET_DELIVERIES_BY_ID, CLEAR_DELIVERIES_SUCCESS, GET_DELIVERIES_AGGREGATIONS, 
    SET_LOADING_DELIVERIES, GET_DELIVERIES_BY_HASH_ID, SYNC_DELIVERY, PUT_DELIVERIES_DUE_DATE 
} from './types';
import { API_DELIVERIES, API_ELASTIC_DELIVERIES, API_BYTES_RESPONSE } from '../_constants/apiConstants';
import { DELIVERIES_NAME, EXCEL_TYPE } from '../_constants/excelConstants';
import serializeQuery from '../_utils/serializeQuery';
import buildQueryMeta from '../_utils/buildQueryMeta';
import buildResponseMeta from '../_utils/buildResponseMeta';
import isEmpty from '../_utils/isEmpty';
import buildQueryDelete from '../_utils/buildQueryDelete';
import store from '../_utils/store';
import { _createDeliveryLoaderTimeout, _deliveryLoadingOff, _deliveryLoadingOn } from './navigation';
import createAxiosInstance from './_axiosConfig';

const apiDeliveriesEndpoint = API_DELIVERIES;

export const getDeliveries = (deliveryId = "") => async dispatch => {
    if (!isEmpty(deliveryId)) {
        deliveryId = "/" + deliveryId;
    }

    let portalCountryCode = store.getState().portal.data.cca2;

    return createAxiosInstance().then(axios => {
        return axios.get(`${apiDeliveriesEndpoint}/${portalCountryCode}${deliveryId}`)
            .then(res => {
                dispatch({
                    type: GET_DELIVERIES_BY_ID,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const getDeliveryByHash = (hashId) => async dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    try {
        const res = await createAxiosInstance().then(axios =>
            axios.get(`${API_DELIVERIES}/${portalCountryCode}/hash/${hashId}`)
        );
        dispatch({
            type: GET_DELIVERIES_BY_HASH_ID,
            payload: res.data
        });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

export const getDeliveriesPage = (meta) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${apiDeliveriesEndpoint}/${portalCountryCode}`;

    return _getPage(portalizedEndpoint, meta, dispatch);
}

export const getElasticDeliveriesPage = (meta) => async dispatch => {
    return await _getPage(API_ELASTIC_DELIVERIES, meta, dispatch);
}

export const exportElasticDeliveriesPage = (meta, downloadFunction = null) => dispatch => {
    if (downloadFunction) {
        let query = buildQueryMeta(meta);
        return createAxiosInstance().then(axios =>
            axios.get(`${API_ELASTIC_DELIVERIES}?${serializeQuery(query)}`, API_BYTES_RESPONSE)
                .then(res => {
                    let blob = new Blob([res.data], EXCEL_TYPE);
                    downloadFunction(blob, DELIVERIES_NAME);
                })
                .catch(err => {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response
                    });
                })
        );
    }
}

export const getDeliveriesDashboardAggregations = (meta) => async dispatch => {
    let query = buildQueryMeta(meta);
    try {
        let res = await createAxiosInstance().then(axios =>
            axios.get(`${API_ELASTIC_DELIVERIES}?aggregationsOnly=true&${serializeQuery(query)}`)
        );
        res = buildResponseMeta(res, meta);
        dispatch({
            type: GET_DELIVERIES_AGGREGATIONS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

export const createDelivery = (delivery, history) => async dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${apiDeliveriesEndpoint}/${portalCountryCode}`;

    try {
        const res = await createAxiosInstance().then(axios =>
            axios.post(portalizedEndpoint, delivery)
        );
        dispatch({
            type: POST_DELIVERIES,
            payload: res.data
        });
        history.push('/deliveries/edit/' + res.data.id);
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

export const updateDelivery = (deliveryId, delivery) => async dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${apiDeliveriesEndpoint}/${portalCountryCode}`;

    try {
        const res = await createAxiosInstance().then(axios =>
            axios.put(`${portalizedEndpoint}/${deliveryId}`, delivery)
        );
        dispatch({
            type: PUT_DELIVERIES,
            payload: res.data
        });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

export const updateDeliveryDate = (DeliveryData) => async dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${apiDeliveriesEndpoint}/bulkUpdateDeliveryDate/${portalCountryCode}`;

    try {
        const res = await createAxiosInstance().then(axios =>
            axios.put(portalizedEndpoint, DeliveryData)
        );
        dispatch({
            type: PUT_DELIVERIES_DUE_DATE,
            payload: res
        });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

export const deleteDelivery = (deliveryId) => async dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${apiDeliveriesEndpoint}/${portalCountryCode}`;

    try {
        await createAxiosInstance().then(axios =>
            axios.delete(`${portalizedEndpoint}/${deliveryId}`)
        );
        dispatch({
            type: DELETE_DELIVERIES,
            payload: [deliveryId]
        });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

export const deleteDeliveriesBulk = (deliveryIds, meta) => async dispatch => {
    let query = buildQueryDelete(deliveryIds);
    let portalCountryCode = store.getState().portal.data.cca2;
    let portalizedEndpoint = `${apiDeliveriesEndpoint}/${portalCountryCode}`;

    try {
        await createAxiosInstance().then(axios =>
            axios.delete(`${portalizedEndpoint}?${serializeQuery(query)}`)
        );

        await new Promise(r => setTimeout(r, 2000));

        dispatch({
            type: DELETE_DELIVERIES,
            payload: deliveryIds
        });

        _getPage(API_ELASTIC_DELIVERIES, meta, dispatch);
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

export const syncDelivery = (deliveryId, xmin) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
        return axios.put(`${API_ELASTIC_DELIVERIES}/${portalCountryCode}/${deliveryId}`)
            .then(res => {
                dispatch({
                    type: SYNC_DELIVERY,
                    payload: res.data
                });
                if (xmin === res.data.xmin)
                    _deliveryLoadingOff([deliveryId], dispatch);
                else
                    _createDeliveryLoaderTimeout(deliveryId, dispatch);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
                _deliveryLoadingOff([deliveryId], dispatch);
            });
    });
}

export const sendContactlessOtp = (deliveryId) => async dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    try {
        const envApiBaseUrl = await createAxiosInstance().then(axios =>
            axios.get(`${API_DELIVERIES}/sendContactlessOtp/${portalCountryCode}/${deliveryId}`)
        );
        return envApiBaseUrl.data.otp;
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }

    return null;
}

export const clearDeliveriesSuccess = () => dispatch => {
    dispatch({
        type: CLEAR_DELIVERIES_SUCCESS,
        payload: ''
    });
}

export const clearDeliveries = () => dispatch => {
    dispatch({
        type: CLEAR_DELIVERIES,
        payload: ''
    });
}

/** Helper functions */

const _getPage = async (apiEndpoint, meta, dispatch) => {
    let query = buildQueryMeta(meta);
    try {
        _setLoading(dispatch, true);

        let res = await createAxiosInstance().then(axios =>
            axios.get(`${apiEndpoint}?${serializeQuery(query)}`)
        );
        res = buildResponseMeta(res, meta);

        dispatch({
            type: GET_DELIVERIES,
            payload: res.data
        });
    }
    catch (err) {
        _setLoading(dispatch, false);

        dispatch({
            type: GET_ERRORS,
            payload: err.response
        });
    }
}

const _setLoading = (dispatch, loading = false) => {
    dispatch({
        type: SET_LOADING_DELIVERIES,
        payload: loading
    });
}