import { GET_AVAILABLE_DATES, GET_AVAILABLE_TIMESLOTS, GET_ERRORS } from './types';
import createAxiosInstance from './_axiosConfig';
import { API_DELIVERY_WINDOWS } from '../_constants/apiConstants';
import store from '../_utils/store';

const getAvailableDatesEndpoint = "getavailabledates";
const getAvailableTimeslotsEndpoint = "getavailabletimeslots";

export const getAvailableDates = () => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    return createAxiosInstance().then(axios => {
        return axios.get(`${API_DELIVERY_WINDOWS}/${getAvailableDatesEndpoint}/${portalCountryCode}`)
            .then(res => {
                dispatch({
                    type: GET_AVAILABLE_DATES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const getAvailableTimeslots = (dateString) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    return createAxiosInstance().then(axios => {
        return axios.get(`${API_DELIVERY_WINDOWS}/${getAvailableTimeslotsEndpoint}/${portalCountryCode}?localDate=${dateString}`)
            .then(res => {
                dispatch({
                    type: GET_AVAILABLE_TIMESLOTS,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}