import clusterIcon from '../_resources/images/map-clusters/cluster-icon.svg';
import storeClusterIcon from '../_resources/images/map-stores/store-cluster-icon.svg';

//colors
export const PRIMARY_COLOR = "#444444";
export const SECONDARY_COLOR = "#EA3659";
export const TERTIARY_COLOR = "#F28333";
export const COMPLEMENTARY_1_COLOR = "#fcd06b";
export const COMPLEMENTARY_2_COLOR = "#f4ede1";
export const COMPLEMENTARY_3_COLOR = "#f6f6ee";
export const COMPLEMENTARY_4_COLOR = "#c1edf3";
export const COMPLEMENTARY_5_COLOR = "#4289a7";
export const BOX_BACKGROUND = "#f9f5ed";
export const ICON_COLOR = "#495057";
export const ERROR_COLOR = '#f44336';
export const WHITE_COLOR = "#FFFFFF";
export const BLACK_COLOR = "#000000";
export const DARK_GREEN_COLOR = "#5fac1a";
export const SLATE_COLOR = "#2b2b2b";
export const LIGHTGREY_COLOR = "#d1d1d1";
export const MIDGREY_COLOR = "#9b9b9b";
export const VERY_LIGHT_GREY = "#eeeeee";
export const DARK_ORANGE_COLOR = "#d4612a";
export const DARK_DARK_BLUE = "#2b5c75";
export const MAROON_COLOR = "#AA102E";
export const AVATAR_COLOR = "";

//export const PRIMARY_COLOR = "#313639";
//export const TERTIARY_COLOR = "#095570"; 
export const SECONDARY_HOVER_COLOR = "#EA3659";
export const STEAL_COLOR = "#828a99";
export const RESIZE_BORDER_COLOR = "#eae8e8"
export const SELECTED_BLUE_COLOR = "#1890ff";
export const SELECTED_BLUE_HOVER_COLOR = '#40a9ff';
export const SELECTED_GREEN_COLOR = "#1e8e3e";
export const SELECTED_HOVER_COLOR = "#656565";
export const DARK_RED_COLOR = "#D0021B";
export const STROKE_GREEN_COLOR = "#005217";
export const LIGHT_GREY_COLOR = "#e3e3e3";
export const DASH_TABLE_GREY_COLOR = "#eeeeee";
export const DARK_BLUE_COLOR = "#4289a7";
export const DARK_GREY_COLOR = "#5d5d5d";
export const BLUE_COLOR = "#4289a7";
export const MEDIUM_GREY_COLOR = "rgba(0, 0, 0, 0.54)";
export const SECONDARY_BLUE_COLOR = "#4872d7";
export const CALL_TO_ACTION_GREEN_COLOR = "#5fac1a";
export const LIGHT_PURPLE_COLOR = "#6446B4";
export const ORANGE_COLOR = "#FF5F1D";
export const LIGHT_BLUE = "#55b6d9";
export const SELECTED_MARKER_COLOR = "#187dba";

//driver avatar settings
export const DRIVER_AVATAR_SATURATION = 40;
export const DRIVER_AVATAR_LIGHTNESS = 55;

// Typography
export const BODY_FONT_COLOR = STEAL_COLOR;

// Map route styling
export const DIRECTIONS_COLOR = "#ea3659";

//fonts
export const TINY_FONT = 12;
export const SMALL_FONT = 14;
export const MEDIUM_FONT = 16;
export const LARGE_FONT = 18;
export const FONT_FAMILY_HEADER = [
    'Nunito Sans',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
].join(',');
export const FONT_FAMILY = [
    'Montserrat',
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',');

//sizes
export const SIDEBAR_WIDTH = 386;
export const MIN_SIDEBAR_WIDTH = 326;
export const MAX_SIDEBAR_WIDTH = 960;
export const SIDEBAR_TOOLBAR_HEIGHT = 48;
export const SECONDARY_NAVBAR_HEIGHT = 48;
export const FILTERS_SIDEBAR_WIDTH = 240;
export const MIN_TABLE_WIDTH = 650;
export const MIN_DASH_TABLE_WIDTH = 240;
export const MAP_HEIGHT = 356;
export const SELECTOR_TO_HEIGHT = 55;
export const APP_BAR_HEIGHT = 69;
export const ADMIN_CONTAINER_HEIGHT = 'calc(100vh - 118px)';
export const DASH_CONTAINER_HEIGHT = 'calc(100vh - 118px)';
export const DASH_TABLE_HEIGHT = 'calc(100vh - 229px)';

//website
export const WEBSITE_BODY_FONT_COLOR = SLATE_COLOR;

//map defaults
export const MAP_CENTER_LAT = -33.9249;
export const MAP_CENTER_LNG = 18.4241;
export const MAP_ZOOM_LEVEL = 9;
export const DRAWING_MAP_ZOOM_LEVEL = 8;
export const MARKER_LABEL_HEIGHT = 37.5;
export const MARKER_LABEL_WIDTH = 13.5;
export const DRIVER_LABEL_HEIGHT = 38;
export const DRIVER_LABEL_WIDTH = 3.5;
export const STORE_LABEL_HEIGHT = 22;
export const STORE_LABEL_WIDTH = -9.5;
export const MARKER_LABEL_HEIGHT_SELECTED = 39.4;
export const STORE_MARKER_Z_INDEX = 300;
export const DRIVER_MARKER_Z_INDEX = 600;
export const SELECTED_MARKER_Z_INDEX = 900;
export const UNSELECTED_MARKER_OPACITY = 0.45;
export const DASH_MAP_HEIGHT = 'calc(100vh - 118px)';
export const PICK_UP_LABEL = { 
  color: "white", 
  fontSize: "14px", 
  fontFamily: FONT_FAMILY,
  textAlign: "center",
  width: "26px",
  height: "26px",
  lineHeight: "26px",
  zIndex: "100",
  borderRadius: "50%",
  //background: ORANGE_COLOR
};
export const DROP_OFF_LABEL = { 
  color: "white", 
  fontSize: "14px", 
  fontFamily: FONT_FAMILY,
  textAlign: "center",
  width: "26px",
  height: "26px",
  lineHeight: "26px",
  zIndex: "100",
  borderRadius: "50%",
  //background: LIGHT_PURPLE_COLOR
};
export const DRIVER_LABEL = { 
  color: "white", 
  fontSize: "11px", 
  fontFamily: FONT_FAMILY,
  textAlign: "center",
  width: "21px",
  height: "21px",
  lineHeight: "21px",
  zIndex: "100",
  borderRadius: "50%",
  border: "1px solid white",
  //background: BLUE_COLOR
};
export const STORE_LABEL = { 
  color: BLACK_COLOR, 
  fontSize: "11px", 
  fontFamily: FONT_FAMILY,
  fontWeight: "600",
  maxWidth: "120px",
  textAlign: "left",
  varticalAlign: "middle",
  paddingLeft: "2px",
  paddingRight: "2px",
  lineHeight: "12px",
  zIndex: "100",
  textShadow: "-1px -1px 0px #FFF, 1px -1px 0px #FFF, -1px 1px 0px #FFF, 1px 1px 0px #FFF"
};

export const MAP_STYLES_DEFAULT = [
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

export const MAP_STYLES_UBER = [
  {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#7c93a3"
          },
          {
              "lightness": "-10"
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#a0a4a5"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#62838e"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#dde3e3"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#3f4a51"
          },
          {
              "weight": "0.30"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "poi.attraction",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.business",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.government",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.place_of_worship",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.school",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.sports_complex",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
          {
              "saturation": "-40"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#bbcacf"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "lightness": "0"
          },
          {
              "color": "#bbcacf"
          },
          {
              "weight": "0.60"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ffffff"
          }
      ]
  },
  {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#a9b4b8"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [
          {
            "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#a4c4da"
          }
      ]
  }
];

export const MAP_STYLES = [
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7f6ee"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7f6ee"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "labels.icon",
        "stylers": [
            {
                "weight": 1.5
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#4289a6"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d9f2d7"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d9f2d7"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#cccccc"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "saturation": 10
            },
            {
                "lightness": 5
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7eaa9"
            }
        ]
    },
    {
        "featureType": "transit.station.airport",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f8f1e0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d3e9ed"
            }
        ]
    }
];

export const MARKER_CLUSTER_STYLES = [ 
  {
    url: clusterIcon,
    height: 40,
    lineHeight: 40,
    width: 42,
    textColor: "#fff",
    fontFamily:"Tahoma",
    textSize:13,
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  {
    url: clusterIcon,
    height: 40,
    lineHeight: 40,
    width: 42,
    textColor: "#fff",
    fontFamily:"Tahoma",
    textSize:13,
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  {
    url: clusterIcon,
    height: 40,
    lineHeight: 40,
    width: 42,
    textColor: "#fff",
    fontFamily:"Tahoma",
    textSize:13,
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  {
    url: clusterIcon,
    height: 40,
    lineHeight: 40,
    width: 42,
    textColor: "#fff",
    fontFamily:"Tahoma",
    textSize:13,
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  {
    url: clusterIcon,
    height: 40,
    lineHeight: 40,
    width: 42,
    textColor: "#fff",
    fontFamily:"Tahoma",
    textSize:13,
    fontStyle: 'normal',
    fontWeight: 'normal'
  }
];

export const STORE_CLUSTER_STYLES = [ 
    {
      url: storeClusterIcon,
      height: 40,
      lineHeight: 40,
      width: 42,
      textColor: "#fff",
      fontFamily:"Tahoma",
      textSize:13,
      fontStyle: 'normal',
      fontWeight: 'normal',
      textShadow: "3px 0 0 #000, 0 -3px 0 #000, 0 3px 0 #000, -3px 0 0 #000"
    },
    {
        url: storeClusterIcon,
        height: 40,
        lineHeight: 40,
        width: 42,
        textColor: "#fff",
        fontFamily:"Tahoma",
        textSize:13,
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    {
        url: storeClusterIcon,
        height: 40,
        lineHeight: 40,
        width: 42,
        textColor: "#fff",
        fontFamily:"Tahoma",
        textSize:13,
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    {
        url: storeClusterIcon,
        height: 40,
        lineHeight: 40,
        width: 42,
        textColor: "#fff",
        fontFamily:"Tahoma",
        textSize:13,
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    {
        url: storeClusterIcon,
        height: 40,
        lineHeight: 40,
        width: 42,
        textColor: "#fff",
        fontFamily:"Tahoma",
        textSize:13,
        fontStyle: 'normal',
        fontWeight: 'normal'
    }
  ];

//polygon styles
export const NEW_POLYGON_OPTIONS = {
    fillColor: BLUE_COLOR,
    fillOpacity: 0.35,
    strokeColor: DARK_BLUE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 2,
    draggable: true,
    editable: true
};

export const EXISTING_POLYGON_OPTIONS = {
    fillColor: SELECTED_GREEN_COLOR,
    fillOpacity: 0.35,
    strokeColor: STROKE_GREEN_COLOR,
    strokeOpacity: 1,
    strokeWeight: 2,
    draggable: true,
    editable: true
};

export const EXISTING_POLYGON_OPTIONS_NOT_EDITABLE = {
    fillColor: SELECTED_GREEN_COLOR,
    fillOpacity: 0.35,
    strokeColor: STROKE_GREEN_COLOR,
    strokeOpacity: 1,
    strokeWeight: 2,
    draggable: false,
    editable: false
};

//borders
export const ERROR_BORDER =  '1px solid '+ERROR_COLOR;

//spacing
export const CONTAINER_PADDING = 20;
export const ITEM_SPACING_SM = 20;
export const ITEM_SPACING_LG = 30;