import { GET_QUOTES, CLEAR_QUOTES, GET_ERRORS, CLEAR_QUOTES_SUCCESS, GET_QUOTE_DETAILS, CLEAR_QUOTE_DETAILS, GET_QUOTE_ACCOUNTS, SET_LOADING_QUOTES } from './types';
import {
    API_CREATE_QUOTES,
    API_UPDATE_QUOTES,
    API_ADD_PARCEL,
    API_REMOVE_PARCEL,
    API_UPDATE_PARCEL,
    API_UPDATE_PARCELS,
    API_SELECT_QUOTE,
    API_SET_TIMESLOT_QUOTE,
    API_UPDATE_ORIGIN_QUOTE_DETAILS,
    API_UPDATE_DESTINATIONS_QUOTE_DETAILS,
    API_UPDATE_ADDITIONAL_QUOTE_DETAILS,
    API_ACCEPT_QUOTE,
    API_SELECT_ACCOUNT,
    API_PAYMENT_FAILED,
    API_CANCEL_QUOTE,
    API_CANCEL_DELIVERY,
    API_GET_QUOTES,
    API_REATTEMPT_DELIVERY,
    API_CLONE_DELIVERY,
    API_CANCEL_AND_RETURN_TO_SENDER,
    API_UPDATE_DELIVERY_CONTACT_INFORMATION,
    API_RECALCULATE_PARCELS,
    API_RECALCULATE_LOGISTICS,
    API_UPDATE_PARCEL_DETAILS,
    API_ACCOUNTS,
    API_REVERSE_LOGISTIC_STATUS,
    API_UPDATE_DELIVERY_PROOF,
    API_PAYMENT_CHECKOUT,
    API_PROCESS_PAYMENT,
    API_PAY_BY_BALANCE,
    API_PAY_BY_SAVED_CARD,
} from '../_constants/apiConstants';
import store from '../_utils/store';
import isEmpty from '../_utils/isEmpty';
import { _createDeliveryLoaderTimeout, _deliveryLoadingOn, _deliveryLoadingOff } from './navigation';
import { getBaseUrlAsync } from '../_utils/getBaseUrlAsync';
import createAxiosInstance from './_axiosConfig';


/**** Quote wizard workflow ****/

//this endpoint creates quotes for each sla based on delivery object supplied
export const createQuotes = (delivery) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn loading on
    _setLoading(dispatch, true);
    return createAxiosInstance().then(axios => {
    return axios.post(`${API_CREATE_QUOTES}/${portalCountryCode}`, delivery)
        .then(res => {
            // here GET_QUOTES automatically turns quote loading off
            dispatch({
                type: GET_QUOTES,
                payload: res.data,

            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // remember to turn loading off
            _setLoading(dispatch, false);
        });
    })
}

//this endpoint updates quotes for each sla based on delivery object supplied
export const updateQuotes = (delivery, deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_QUOTES}/${portalCountryCode}/${deliveryId}`, delivery)
        .then(res => {
            // here GET_QUOTES automatically turns quote loading off
            dispatch({
                type: GET_QUOTES,
                payload: res.data,
            });
            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R 
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

export const addParcel = (parcel, deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_ADD_PARCEL}/${portalCountryCode}`, parcel)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

export const updateParcel = (parcel) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_PARCEL}/${portalCountryCode}`, parcel)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data,
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });})
}

export const removeParcel = (deliveryId, parcelId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_REMOVE_PARCEL}/${portalCountryCode}/${deliveryId}/${parcelId}`)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

export const updateParcels = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([request.deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_PARCELS}/${portalCountryCode}`, request)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout([request.deliveryId], dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([request.deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

//this endpoint selects a quote (from the list of available sla quotes) on a delivery
export const changeTimeSlot = (deliveryId, timeslot) => dispatch => {
    if (isEmpty(timeslot)) {
        timeslot = "";
    }
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_SET_TIMESLOT_QUOTE}/${portalCountryCode}/${deliveryId}?deliveryTime=${timeslot}`)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

//this endpoint selects a quote (from the list of available sla quotes) on a delivery
export const selectQuote = (deliveryId, quoteId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_SELECT_QUOTE}/${portalCountryCode}/${deliveryId}/${quoteId}`)
        .then(res => {
            // here GET_QUOTES automatically turns quote loading off
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

//update quote details (i.e., contact numbers etc.)
export const updateOriginQuoteDetails = (delivery, deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_ORIGIN_QUOTE_DETAILS}/${portalCountryCode}/${deliveryId}`, delivery)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

export const updateDestinationQuoteDetails = (delivery, deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_DESTINATIONS_QUOTE_DETAILS}/${portalCountryCode}/${deliveryId}`, delivery)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

export const updateAdditionalQuoteDetails = (delivery, deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_ADDITIONAL_QUOTE_DETAILS}/${portalCountryCode}/${deliveryId}`, delivery)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);


            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

//accept a quote
export const acceptQuote = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_ACCEPT_QUOTE}/${portalCountryCode}/${deliveryId}`)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

//select account to pay against
export const selectAccount = (payment) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_SELECT_ACCOUNT}/${portalCountryCode}`, payment)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

//Pay postpay or by credit balance
export const payByBalance = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_PAY_BY_BALANCE}/${portalCountryCode}/${deliveryId}`)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

//generate a checkout it for peach payments
export const paymentCheckout = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_PAYMENT_CHECKOUT}/${portalCountryCode}/${deliveryId}`)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

export const payBySavedCard = (deliveryId, cardId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // first we turn quote loading on
    _setLoading(dispatch, true);
    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_PAY_BY_SAVED_CARD}/${portalCountryCode}/${deliveryId}/${cardId}`)
        .then(res => {
            dispatch({
                // here GET_QUOTES automatically turns quote loading off
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

            // remember to turn quote loading off
            _setLoading(dispatch, false);
        });
    })
}

//generate a checkout it for peach payments
export const processCardPayment = (paymentResponse) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_PROCESS_PAYMENT}/${portalCountryCode}/`, paymentResponse)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

//payment failed
export const paymentFailed = (payment) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_PAYMENT_FAILED}/${portalCountryCode}`, payment)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

/**** Delivery card cancellation, reattempt and clone functions ****/

//cancel the quote
export const cancelQuote = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_CANCEL_QUOTE}/${portalCountryCode}/${deliveryId}`)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}

//cancel the delivery after it has been paid for
export const cancelDelivery = (deliveryId, note) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_CANCEL_DELIVERY}/${portalCountryCode}/${deliveryId}`, note)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}

//cancel the delivery and create a new return to sender delivery
export const cancelAndReturnToSender = (deliveryId, note) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_CANCEL_AND_RETURN_TO_SENDER}/${portalCountryCode}/${deliveryId}`, note)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}

//reattempt the delivery (i.e., create clone of the delivery with the SAME waybill number)
export const reattemptDelivery = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_REATTEMPT_DELIVERY}/${portalCountryCode}/${deliveryId}`)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}

//clone the delivery (i.e., create clone of the delivery with NEW waybill number)
export const cloneDelivery = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_CLONE_DELIVERY}/${portalCountryCode}/${deliveryId}`)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });})
}


/**** ADMIN edit and adjustment functions ****/

export const updateDeliveryContactInformation = (data, deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    // and turn on delivery card loader as well
    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_DELIVERY_CONTACT_INFORMATION}/${portalCountryCode}/${deliveryId}`, data)
        .then(res => {
            dispatch({
                type: GET_QUOTE_DETAILS,
                payload: res.data
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);

        });
    })
}

export const recalculateParcels = (deliveryId, request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_RECALCULATE_PARCELS}/${portalCountryCode}/${deliveryId}`, request)
        .then(res => {
            dispatch({
                type: GET_QUOTE_DETAILS,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}

export const recalculateLogistics = (deliveryId, request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_RECALCULATE_LOGISTICS}/${portalCountryCode}/${deliveryId}`, request)
        .then(res => {
            dispatch({
                type: GET_QUOTE_DETAILS,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}

export const updateParcelDetails = (request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([request.deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_PARCEL_DETAILS}/${portalCountryCode}`, request)
        .then(res => {
            dispatch({
                type: GET_QUOTE_DETAILS,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout([request.deliveryId], dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([request.deliveryId], dispatch);
        });
    })
}

export const updateDeliveryProof = (deliveryId, request) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_UPDATE_DELIVERY_PROOF}/${portalCountryCode}/${deliveryId}`, request)
        .then(res => {
            dispatch({
                type: GET_QUOTE_DETAILS,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}


export const reverseLogisticStatus = (deliveryId, logisticStatusId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;

    _deliveryLoadingOn([deliveryId], dispatch);
    return createAxiosInstance().then(axios => {
    return axios.put(`${API_REVERSE_LOGISTIC_STATUS}/${portalCountryCode}/${deliveryId}/${logisticStatusId}`)
        .then(res => {
            dispatch({
                type: GET_QUOTE_DETAILS,
                payload: res.data,
            });

            // create a timeout to fetch each delivery and turn off loader in case we dont get a response from signal R
            _createDeliveryLoaderTimeout(deliveryId, dispatch);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });

            // turn off loaders in case of an error
            _deliveryLoadingOff([deliveryId], dispatch);
        });
    })
}


//get quotes based on delivery id
export const getQuotes = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.get(`${API_GET_QUOTES}/${portalCountryCode}/${deliveryId}`)
        .then(res => {
            dispatch({
                type: GET_QUOTES,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

//get quotes based on delivery id for the details view
export const getQuoteDetails = (deliveryId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return getBaseUrlAsync().then(apiBaseUrl => {
        return createAxiosInstance().then(axios => {
        return axios.get(`${API_GET_QUOTES}/${portalCountryCode}/${deliveryId}`)
            .then(res => {
                dispatch({
                    type: GET_QUOTE_DETAILS,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
})
}

// get accounts for a user to populate in the pay step of the quote wizard
export const getQuoteAccounts = (accountId = "") => dispatch => {

    if (!isEmpty(accountId)) {
        accountId = `/${accountId}`;
    }
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
    return axios.get(`${API_ACCOUNTS}/${portalCountryCode}${accountId}`)
        .then(res => {
            dispatch({
                type: GET_QUOTE_ACCOUNTS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
    })
}

export const clearQuoteDetails = () => dispatch => {
    return dispatch({
        type: CLEAR_QUOTE_DETAILS,
        payload: ''
    });
}

export const clearQuotes = () => dispatch => {
    dispatch({
        type: CLEAR_QUOTES,
        payload: ''
    });
}

export const clearQuotesSuccess = () => dispatch => {
    dispatch({
        type: CLEAR_QUOTES_SUCCESS,
        payload: ''
    });
}


/** Helper function and loading functions */
const _setLoading = (dispatch, loading = false) => {
    dispatch({
        type: SET_LOADING_QUOTES,
        payload: loading
    });
}



