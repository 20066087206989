import store from '../_utils/store';
import { API_MOBILE_DEVICES, API_BYTES_RESPONSE } from "../_constants/apiConstants";
import { GET_ERRORS, GET_MOBILE_DEVICES, POST_MOBILE_DEVICES, PUT_MOBILE_DEVICES, CLEAR_MOBILE_DEVICES, DELETE_MOBILE_DEVICES, CLEAR_MOBILE_DEVICES_SUCCESS, NOTIFY_MOBILE_DEVICE } from './types';
import buildQueryMeta from '../_utils/buildQueryMeta';
import buildResponseMeta from '../_utils/buildResponseMeta';
import serializeQuery from '../_utils/serializeQuery';
import buildQueryDelete from '../_utils/buildQueryDelete';
import { MOBILE_DEVICES_NAME, EXCEL_TYPE } from '../_constants/excelConstants';
import createAxiosInstance from './_axiosConfig';

export const getMobileDevice = (mobileDeviceId) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.get(`${API_MOBILE_DEVICES}/${portalCountryCode}/${mobileDeviceId}`)
            .then(res => {
                dispatch({
                    type: GET_MOBILE_DEVICES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const getMobileDevicesPage = (meta) => dispatch => {
    return _getPage(meta, dispatch);
}

export const registerMobileDevice = (mobileDevice) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.post(`${API_MOBILE_DEVICES}/${portalCountryCode}`, mobileDevice)
            .then(res => {
                dispatch({
                    type: POST_MOBILE_DEVICES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const updateMobileDevice = (mobileDeviceId, mobileDevice) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.put(`${API_MOBILE_DEVICES}/${portalCountryCode}/${mobileDeviceId}`, mobileDevice)
            .then(res => {
                dispatch({
                    type: PUT_MOBILE_DEVICES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const deleteMobileDevices = (mobileDeviceIds, meta) => dispatch => {
    let query = buildQueryDelete(mobileDeviceIds);
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.delete(`${API_MOBILE_DEVICES}/${portalCountryCode}?${serializeQuery(query)}`)
            .then(res => {
                dispatch({
                    type: DELETE_MOBILE_DEVICES,
                    payload: mobileDeviceIds
                });
            })
            .then(res => {
                _getPage(meta, dispatch);
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}


export const notifyMobileDevice = (mobileDeviceId, message) => dispatch => {
    let portalCountryCode = store.getState().portal.data.cca2;
    return createAxiosInstance().then(axios => {
        return axios.put(`${API_MOBILE_DEVICES}/${portalCountryCode}/notify/${mobileDeviceId}`, message)
            .then(res => {
                dispatch({
                    type: NOTIFY_MOBILE_DEVICE,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const clearMobileDevices = () => dispatch => {
    dispatch({
        type: CLEAR_MOBILE_DEVICES,
        payload: ''
    });
}

export const clearMobileDevicesSuccess = () => dispatch => {
    dispatch({
        type: CLEAR_MOBILE_DEVICES_SUCCESS,
        payload: ''
    });
}


let _getPage = (meta, dispatch) => {
    let portalCountryCode = store.getState().portal.data.cca2;
    let query = buildQueryMeta(meta);
    return createAxiosInstance().then(axios => {
        return axios.get(`${API_MOBILE_DEVICES}/${portalCountryCode}?${serializeQuery(query)}`)
            .then(res => {
                res = buildResponseMeta(res, meta);
                dispatch({
                    type: GET_MOBILE_DEVICES,
                    payload: res.data
                });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                });
            });
    });
}

export const exportMobileDevices = (meta, downloadFunction=null) => dispatch => {
    if(downloadFunction)
    {
        let query = buildQueryMeta(meta);
        let portalCountryCode = store.getState().portal.data.cca2;
        return createAxiosInstance().then(axios => {
            return axios.get(`${API_MOBILE_DEVICES}/${portalCountryCode}?${serializeQuery(query)}`, API_BYTES_RESPONSE)
                .then(res => {
                    let blob = new Blob([res.data], EXCEL_TYPE);
                    downloadFunction(blob, MOBILE_DEVICES_NAME);
                })
                .catch(err => {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response
                    });
            });
        });
    }    
}


